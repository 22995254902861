import AbstractApiRepository from '../../../../base/api/AbstractApiRepository';
import { UploadDocumentDto } from '../../dto/UploadDocumentDto';

export default class DocumentApiRepository extends AbstractApiRepository {
  uploadDocument = (dto: UploadDocumentDto, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('entityId', dto.entityId);
    formData.append('entityType', dto.entityType);
    formData.append('documentType', dto.documentType);
    if (!!dto.documentName) {
      formData.append('documentName', dto.documentName);
    }
    return this.apiClient.post({
      url: '/documents',
      data: formData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  };

  uploadDocumentWithUrl = (dto: UploadDocumentDto) => {
    return this.apiClient.post({
      url: '/documents/fileUrl',
      data: dto,
    });
  }

  getDocument = (id: string) => {
    return this.apiClient.get({
      url: `/documents/${id}`,
    });
  };

  getDocumentFile = (id: string) => {
    return this.apiClient.get({
      url: `/documents/${id}/file`,
    });
  };

  deleteDocument = (id: string) => {
    return this.apiClient.delete({
      url: `/documents/${id}`,
    });
  };
}
