import AbstractModel from '../../../base/AbstractModel';

export class Tariff extends AbstractModel {
  id: string | null = null;
  duration: number | null = null;
  price: number | null = null;
  isActive: boolean | null = null;
  name: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
