import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import IApiClient from '../IApiClient';
import { IAxiosConfig } from './IAxiosInterfaces';

export default class AxiosClient implements IApiClient {
  readonly SUCCESS_STATUSES = [200, 201];
  readonly SERVER_ERROR = 500;
  readonly UNAUTHENTICATED = 401;

  api: AxiosInstance;

  private onUnauthenticated?: (originalRequest: AxiosRequestConfig) => void;

  constructor(config?: AxiosRequestConfig) {
    this.api = axios.create(config);
    this.api.defaults.baseURL = process.env.REACT_APP_API_URL;

    this.setInterceptorRequest();
    this.setInterceptorResponse();
  }

  setAccessToken = (token: string) => {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  clearAccessToken = () => {
    this.api.defaults.headers.common['Authorization'] = '';
  };

  setOnUnauthenticated = (func: (originalRequest: AxiosRequestConfig) => void) => {
    this.onUnauthenticated = func;
  };

  get = <T extends {}>(config: IAxiosConfig) => {
    return this.api.get<T>(config.url, config.config);
  };

  post = <T extends {}>(config: IAxiosConfig) => {
    return this.api.post<T>(config.url, config.data, config.config);
  };

  put = <T extends {}>(config: IAxiosConfig) => {
    return this.api.put<T>(config.url, config.data, config.config);
  };

  delete = <T extends {}>(config: IAxiosConfig) => {
    return this.api.delete<T>(config.url, config.config);
  };

  protected getApiErrors = (error: any) => {
    if (error?.message) {
      // if (!Array.isArray(error?.errors) && error?.errors) {
      //   const errorsArray = Object.values(error?.errors);
      //   const errors = Array.prototype.concat.apply([], errorsArray);
      //   Notification.showError(errors.join('\n') || 'Unknown error');
      // } else {
      //   Notification.showError(error?.message || 'Unknown error');
      // }
    }
  };

  private setInterceptorRequest = () => {
    this.api.interceptors.request.use(
      async (config) => {
        const newConfig: AxiosRequestConfig = {
          ...config,
          headers: {
            'Content-Type': 'application/json',
            ...config.headers,
          },
        };

        return newConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );
  };

  private setInterceptorResponse = () => {
    this.api.interceptors.response.use(
      (response) => {
        if (!this.SUCCESS_STATUSES.includes(response.status)) {
          return Promise.reject(response);
        }
        // if (response?.data?.message) {
        //   Notification.showSuccess(response?.data?.message);
        // }

        return response;
      },
      (error) => {
        // global showing error messages
        // console.log('response error', { error });

        let originalRequest = error?.config;
        if (error?.response?.status === this.UNAUTHENTICATED && !originalRequest?._retry && this.onUnauthenticated) {
          return this.onUnauthenticated(originalRequest);
        }

        this.getApiErrors(error?.response?.data);

        // if (error.response?.status === this.SERVER_ERROR) {
        //   Notification.showError('Server error');
        // }

        return Promise.reject(error);
      },
    );
  };
}
