import { makeAutoObservable, runInAction } from 'mobx';
import LecturesService from './LecturesService';
import { Lecture } from './models/Lecture';

class LecturesStore {
  isLoading = true;
  lectures: Lecture[] = [];

  private lecturesService: LecturesService;

  constructor() {
    this.lecturesService = new LecturesService();
    makeAutoObservable(this);
  }

  init = () => {
    this.getAll();
  };

  getAll = () => {
    this.isLoading = true;
    this.lecturesService
      .getAll()
      .then(lectures => {
        runInAction(() => {
          this.lectures = lectures;
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  create = (num: number) => {
    this.isLoading = true;
    this.lecturesService
      .create({ num })
      .then(lecture => {
        runInAction(() => {
          this.lectures = this.lecturesService.sortLectures([...this.lectures, lecture]);
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  update = ({ id, num }: { id: string; num: number }) => {
    this.isLoading = true;
    this.lecturesService
      .update(id, { num })
      .then(lecture => {
        runInAction(() => {
          const index = this.lectures.findIndex(l => l.id === id);
          if (index > -1) {
            this.lectures[index] = lecture;
          }
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  delete = (id: string | null) => {
    if (!id) {
      return;
    }
    this.isLoading = true;
    this.lecturesService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.lectures = this.lectures.filter(l => l.id !== id);
        });
      })
      .catch(error => {
        //@TODO aler error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}

export default LecturesStore;
