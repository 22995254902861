import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../base/components/Loader';
import NumberModal from '../../../base/components/NumberModal';
import LecturesStore from '../LecturesStore';
import { Lecture } from '../models/Lecture';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';

type ModalState = {
  isVisible: boolean;
  lecture: Lecture | null;
};

const LecturesPage = observer(() => {
  const lecturesStore = useLocalObservable(() => new LecturesStore());
  const { isLoading, lectures } = lecturesStore;
  const { init } = lecturesStore;
  const [modalState, setModalState] = useState<ModalState>({
    isVisible: false,
    lecture: null,
  });
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState<ModalState>({
    isVisible: false,
    lecture: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const openModal = (lecture?: Lecture) =>
    setModalState({
      isVisible: true,
      lecture: lecture ?? null,
    });
  const closeModal = () =>
    setModalState({
      isVisible: false,
      lecture: null,
    });

  const openConfirmDeleteModal = (lecture: Lecture) =>
    setConfirmDeleteModalState({
      isVisible: true,
      lecture,
    });
  const closeConfirmDeleteModal = () =>
    setConfirmDeleteModalState({
      isVisible: false,
      lecture: null,
    });
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.lecture?.id) {
      lecturesStore.delete(confirmDeleteModalState.lecture.id);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant="contained" onClick={() => openModal()}>
        Создать
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {lectures.map((lecture) => (
          <ListItem divider key={lecture.id} onClick={() => navigate(`/lessons?lectureId=${lecture.id}`)}>
            <ListItemText primary={`Лекция ${lecture.num}`} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => openModal(lecture)} aria-label="edit">
                <Edit />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  //   event.preventDefault();
                  openConfirmDeleteModal(lecture);
                }}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <NumberModal
        isVisible={modalState.isVisible}
        initialNum={modalState.lecture?.num}
        close={closeModal}
        onSubmit={(num) => {
          if (modalState.lecture?.id) {
            return lecturesStore.update({ id: modalState.lecture.id, num });
          }
          lecturesStore.create(num);
        }}
      />
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
});

export default LecturesPage;
