import React, { useEffect } from 'react';
import { Box, Button, Divider, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import LessonCreateEditStore from '../stores/LessonCreateEditStore';
import CreateUpdateLessonForm from '../types/CreateUpdateLessonForm';
import Loader from '../../../base/components/Loader';
import LessonCreateUpdateFiles from '../components/LessonCreateUpdateFiles';
import { useQuery } from '../../../base/hooks/useQuery';

const LessonCreateUpdatePage = observer(() => {
  const query = useQuery();
  const lectureId = query.get('lectureId');
  const { lessonId } = useParams();
  const store = useLocalObservable(() => new LessonCreateEditStore());
  const { isLoading, lesson } = store;
  const { init } = store;

  useEffect(() => {
    init({ lessonId });
  }, [lessonId, init]);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name')?.toString();

    if (!lectureId || !name) {
      return;
    }

    const form: CreateUpdateLessonForm = {
      lectureId: lectureId,
      name: name,
      textPdd: data.get('textPdd')?.toString(),
      textFz: data.get('textFz')?.toString(),
      textKoap: data.get('textKoap')?.toString(),
    };
    store.submit(form, () => navigate(-1));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Box component={'form'} onSubmit={handleSubmit}>
        <TextField
          id="name"
          label="Тема"
          name="name"
          margin="normal"
          defaultValue={lesson?.name}
          multiline={true}
          maxRows={50}
          required
          fullWidth
          autoFocus
        />
        <TextField
          id="textPdd"
          label="Текст ПДД"
          name="textPdd"
          margin="normal"
          defaultValue={lesson?.textPdd}
          multiline
          maxRows={50}
          fullWidth
        />
        <TextField
          id="textFz"
          label="Текст ФЗ"
          name="textFz"
          margin="normal"
          defaultValue={lesson?.textFz}
          multiline
          maxRows={50}
          fullWidth
        />
        <TextField
          id="textKoap"
          label="Текст КоАП"
          name="textKoap"
          margin="normal"
          defaultValue={lesson?.textKoap}
          multiline
          maxRows={50}
          fullWidth
        />
        <Divider />
        <LessonCreateUpdateFiles store={store} />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {lessonId ? 'Сохранить' : 'Создать'}
        </Button>
      </Box>
    </div>
  );
});

export default LessonCreateUpdatePage;
