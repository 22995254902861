import { Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

type FilePickerModalProps = {
  isVisible: boolean;
};

const FilePickerModal = ({ isVisible, ...contentProps }: FilePickerModalProps & ContentProps) => {
  return (
    <Modal
      open={isVisible}
      onClose={contentProps.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Content {...contentProps} />
      </div>
    </Modal>
  );
};

type ContentProps = {
  close: () => void;
  accept?: Accept;
  onSelect: (file: File, docName?: string) => void;
  isWithDocumentName?: boolean;
  documentName?: string;
};

const Content = ({ close, accept, onSelect, documentName, isWithDocumentName }: ContentProps) => {
  const [docName, setDocName] = useState(documentName ?? '');
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxFiles: 1,
  });

  useEffect(() => {
    if (!isWithDocumentName && acceptedFiles.length > 0) {
      close();
      onSelect(acceptedFiles[0]);
    }
  }, [acceptedFiles, onSelect, close, isWithDocumentName]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Box sx={styleBox}>
      {!!isWithDocumentName && (
        <TextField
          id="docName"
          label="Название"
          name="docName"
          margin="normal"
          value={documentName}
          onChange={event => setDocName(event.target.value)}
          required
          fullWidth
          autoFocus
        />
      )}
      <div
        {...getRootProps({
          //@ts-ignore
          style,
        })}
      >
        <input {...getInputProps()} />
        <p>Переместите файл или кликните, чтобы загрузить</p>
      </div>
      {!!acceptedFiles[0] && (
        <>
          <h3>Выбран</h3> {acceptedFiles[0].name}
        </>
      )}

      {isWithDocumentName && (
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            if (docName && acceptedFiles[0]) {
              close();
              onSelect(acceptedFiles[0], docName);
            }
          }}
        >
          Готово
        </Button>
      )}
    </Box>
  );
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const styleBox = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default FilePickerModal;
