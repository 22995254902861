import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactPlayer from 'react-player';
import { Box, Button } from '@mui/material';
import DocumentHelper from '../../documents/helpers/DocumentsHelper';
import FilePickerModal from '../../../base/components/FilePickerModal';
import { DocumentType } from '../../documents/models/Document';
import QuestionCreateUpdateStore from '../stores/QuestionCreateUpdateStore';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';

type QuestionCreateUpdateFilesProps = {
  store: QuestionCreateUpdateStore;
};

type ConfirmDeleteModalState = {
  isVisible: boolean;
  documentType: DocumentType | null;
};

const InitConfirmDeleteModalState: ConfirmDeleteModalState = {
  isVisible: false,
  documentType: null,
};

const QuestionCreateUpdateFiles = observer(({ store }: QuestionCreateUpdateFilesProps) => {
  const [filePickerProps, setFilePickerProps] = useState<{
    isVisible: boolean;
    documentType?: DocumentType;
  }>({
    isVisible: false,
  });
  const { files, onSelectFile, deleteFile } = store;

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>(InitConfirmDeleteModalState);

  const open = (documentType: DocumentType) =>
    setFilePickerProps({
      isVisible: true,
      documentType,
    });
  const close = () =>
    setFilePickerProps({
      isVisible: false,
    });

  const openConfirmDeleteModal = (documentType: DocumentType) =>
    setConfirmDeleteModalState({
      isVisible: true,
      documentType,
    });
  const closeConfirmDeleteModal = () => setConfirmDeleteModalState(InitConfirmDeleteModalState);
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.documentType) {
      deleteFile(confirmDeleteModalState.documentType);
    }
  };

  const video = files.get(DocumentType.Video);
  const image = files.get(DocumentType.Image);
  return (
    <>
      <h3>Файлы</h3>
      <h4>Картинка</h4>
      <Box>
        {!!image && (
          <Box
            component="img"
            sx={{
              height: '50%',
              width: '50%',
            }}
            alt="Картинка"
            src={image.url}
          />
        )}
      </Box>
      {!image && (
        <Button variant="contained" onClick={() => open(DocumentType.Image)}>
          Загрузить
        </Button>
      )}
      {!!image && (
        <Button variant="contained" color="error" onClick={() => openConfirmDeleteModal(DocumentType.Image)}>
          Удалить
        </Button>
      )}
      <h4>Видео объяснение</h4>
      {!!video && <ReactPlayer url={video.url} width="30%" height="30%" controls={true} />}
      {!video && (
        <Button variant="contained" onClick={() => open(DocumentType.Video)}>
          Загрузить
        </Button>
      )}
      {!!video && (
        <Button variant="contained" color="error" onClick={() => openConfirmDeleteModal(DocumentType.Video)}>
          Удалить
        </Button>
      )}
      <FilePickerModal
        isVisible={filePickerProps.isVisible}
        close={close}
        accept={DocumentHelper.mapDocumentTypeFilePickerAccept[filePickerProps.documentType ?? '']}
        onSelect={(file) => {
          filePickerProps.documentType && onSelectFile(filePickerProps.documentType, file);
        }}
      />

      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
});

export default QuestionCreateUpdateFiles;
