import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalObservable } from 'mobx-react';
import { TariffsStore } from '../stores/TariffsStore';
import Loader from '../../../base/components/Loader';
import { Button, Checkbox, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { CreateTariffModal } from './components/CreateTariffModal';
import { CreateTariffDto } from '../dto/CreateTariffDto';
import { UpdateTariffModal } from './components/UpdateTariffModal';
import { UpdateTariffDto } from '../dto/UpdateTariffDto';
import { Tariff } from '../models/Tariff';

export const TariffsPage = observer(() => {
  const navigate = useNavigate();
  const tariffsStore = useLocalObservable(() => new TariffsStore());

  const [createVisible, setCreateVisible] = useState(false);
  const [updateProps, setUpdateProps] = useState<{
    visible: boolean;
    tariff: Tariff | null;
  }>({
    visible: false,
    tariff: null,
  });

  useEffect(() => {
    tariffsStore.getTariffs();
  }, []);

  const handleOpenCreate = () => setCreateVisible(true);
  const handleCloseCreate = () => setCreateVisible(false);

  const handleOpenUpdate = (tariff: Tariff) =>
    setUpdateProps({
      tariff,
      visible: true,
    });
  const handleCloseUpdate = () =>
    setUpdateProps({
      tariff: null,
      visible: false,
    });

  const handleSubmitCreate = (dto: CreateTariffDto) => {
    tariffsStore.createTariff(dto);
  };

  const handleSubmitUpdate = (dto: UpdateTariffDto) => {
    tariffsStore.updateTariff(dto);
  };

  if (tariffsStore.loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant={'contained'} onClick={handleOpenCreate}>
        Создать
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {tariffsStore.tariffs.map((tariff) => (
          <ListItem divider key={tariff.id} onClick={() => {}}>
            <ListItemText primary={`${tariff.name ?? ''} ${tariff.price}`} />
            <ListItemSecondaryAction>
              <Checkbox disabled checked={!!tariff.isActive} />
              <IconButton onClick={() => handleOpenUpdate(tariff)} aria-label="edit">
                <Edit />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <CreateTariffModal
        isVisible={createVisible}
        handleCloseModal={handleCloseCreate}
        handleSubmit={handleSubmitCreate}
      />
      <UpdateTariffModal
        isVisible={updateProps.visible}
        handleCloseModal={handleCloseUpdate}
        handleSubmit={handleSubmitUpdate}
        tariff={updateProps.tariff}
      />
    </div>
  );
});
