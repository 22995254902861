import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const regex = /^[0-9\b]+$/;

interface ITariffFieldsFormProps {
  name?: string;
  duration?: number;
  price?: number;
}

export const TariffFieldsForm = (props: ITariffFieldsFormProps) => {
  const [duration, setDuration] = useState(props.duration);
  const [price, setPrice] = useState(props.price);

  const handleChangePrice = (e: any) => {
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPrice(e.target.value);
    }
  };

  const handleChangeDuration = (e: any) => {
    if (e.target.value === '' || regex.test(e.target.value)) {
      setDuration(e.target.value);
    }
  };

  return (
    <div>
      <TextField defaultValue={props.name} margin="normal" required fullWidth id="name" label="Название" name="name" />
      <TextField
        margin="normal"
        required
        fullWidth
        id="duration"
        label="Срок в днях"
        name="duration"
        value={duration}
        onChange={handleChangeDuration}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="price"
        label="Цена ₽"
        name="price"
        value={price}
        onChange={handleChangePrice}
      />
    </div>
  );
};
