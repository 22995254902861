import AuthApiRepository from './repositories/AuthApiRepository';
import AuthLocalRepository from './repositories/AuthLocalRepository';
import ILoginRequest from './types/ILoginRequest';
import jwtDecode from 'jwt-decode';

export default class AuthService {
  private apiRepository = new AuthApiRepository();
  private localRepository = new AuthLocalRepository();

  login = (data: ILoginRequest) => {
    return this.apiRepository.login(data).then(async (response) => {
      const jwtPayload: { roles?: string[] } = jwtDecode(response.data.accessToken);

      if (!jwtPayload?.roles?.includes('admin') ?? false) {
        throw new Error();
      }

      await this.localRepository.set(response.data);
      this.apiRepository.setAccessToken(response.data.accessToken);

      return response.data;
    });
  };

  logout = () => {
    return this.apiRepository.logout().finally(async () => {
      await this.localRepository.removeAll();
      this.apiRepository.clearAccessToken();
    });
  };

  setRefreshTokenEvent = (onError: () => void) => {
    this.apiRepository.setOnUnauthenticated(async (originalRequest) => {
      if (originalRequest.url?.includes('/refresh')) {
        onError();
        return;
      }
      //@ts-ignore
      if (originalRequest._retry) {
        return onError();
      }
      //@ts-ignore
      originalRequest._retry = true;
      const refreshToken = (await this.loadLocalData())?.refreshToken;
      if (!refreshToken) {
        this.apiRepository.clearAccessToken();
        await this.localRepository.removeAll();

        return onError();
      }
      return this.apiRepository
        .refreshToken({
          refreshToken,
        })
        .then(async (response) => {
          await this.localRepository.set(response.data);
          this.apiRepository.setAccessToken(response.data.accessToken);
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: `Bearer ${response.data.accessToken}`,
          };

          return this.apiRepository.apiClient.api(originalRequest);
        })
        .catch(async () => {
          this.apiRepository.clearAccessToken();
          await this.localRepository.removeAll();

          return onError();
        });
    });
  };

  loadLocalData = () => {
    return this.localRepository.get().then((data) => {
      if (data) {
        this.apiRepository.setAccessToken(data.accessToken);

        return data;
      }
      return null;
    });
  };
}
