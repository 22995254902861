import React from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';

type IConfirmDeleteModalProps = {
  isVisible: boolean;
  close: () => void;
  onConfirm: () => void;
};

export const ConfirmDeleteModal = ({ isVisible, close, onConfirm }: IConfirmDeleteModalProps) => {
  return (
    <Modal
      open={isVisible}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography>Вы действительно хотите удалить?</Typography>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 3, mb: 2 }}>
          <Button variant="contained" sx={{}} onClick={close}>
            Нет
          </Button>
          <Button color={'error'} variant="contained" sx={{}} onClick={onConfirm}>
            Да
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
