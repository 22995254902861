import AbstractModel from '../../../base/AbstractModel';

export default class Document extends AbstractModel {
  id: string | null = null;
  entityId: string | null = null;
  entityType: EntityType | null = null;
  documentType: DocumentType | null = null;
  path: string | null = null;
  mimeType: string | null = null;
  name: string | null = null;
  documentName: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

export enum EntityType {
  Lesson = 'LESSON',
  Question = 'QUESTION',
}

export enum DocumentType {
  Video = 'VIDEO',
  Image = 'IMAGE',
  PdfDocument = 'PDF_DOCUMENT',
}
