import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NumberModal from '../../../base/components/NumberModal';
import Ticket from '../model/Ticket';
import TicketsStore from '../TicketsStore';
import Loader from '../../../base/components/Loader';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';

type ConfirmDeleteModalState = {
  isVisible: boolean;
  ticket: Ticket | null;
};

const InitConfirmDeleteModalState: ConfirmDeleteModalState = {
  isVisible: false,
  ticket: null,
};

const TicketsPage = observer(() => {
  const navigate = useNavigate();
  const store = useLocalObservable(() => new TicketsStore());
  const { isLoading, tickets, init } = store;

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>(InitConfirmDeleteModalState);

  useEffect(() => {
    init();
  }, [init]);

  const [modalState, setModalState] = useState<{
    isVisible: boolean;
    ticket: Ticket | null;
  }>({
    isVisible: false,
    ticket: null,
  });

  const openConfirmDeleteModal = (ticket: Ticket) =>
    setConfirmDeleteModalState({
      isVisible: true,
      ticket,
    });
  const closeConfirmDeleteModal = () => setConfirmDeleteModalState(InitConfirmDeleteModalState);
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.ticket) {
      store.delete(confirmDeleteModalState.ticket.id);
    }
  };

  const openModal = (ticket?: Ticket) =>
    setModalState({
      isVisible: true,
      ticket: ticket ?? null,
    });
  const closeModal = () =>
    setModalState({
      isVisible: false,
      ticket: null,
    });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant="contained" onClick={() => openModal()}>
        Создать
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {tickets.map((ticket) => (
          <ListItem divider key={ticket.id} onClick={() => navigate(`/questions?ticketId=${ticket.id}`)}>
            <ListItemText primary={`Билет ${ticket.num}`} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => openModal(ticket)} aria-label="edit">
                <Edit />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  //   event.preventDefault();
                  openConfirmDeleteModal(ticket);
                }}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <NumberModal
        isVisible={modalState.isVisible}
        initialNum={modalState.ticket?.num}
        close={closeModal}
        onSubmit={(num) => {
          if (modalState.ticket?.id) {
            return store.update({ id: modalState.ticket.id, num });
          }
          store.create(num);
        }}
      />

      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
});

export default TicketsPage;
