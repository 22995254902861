import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { CreateUpdateLessonDto } from '../dto/CreateUpdateLessonDto';

export default class LessonsApiRepository extends AbstractApiRepository {
  getAll = () => {
    return this.apiClient.get({
      url: '/lessons',
    });
  };

  getByLectureId = (lectureId: string) => {
    return this.apiClient.get({
      url: `/lessons?lectureId=${lectureId}`,
    });
  };

  getById = (id: string) => {
    return this.apiClient.get({
      url: `/lessons/${id}`,
    });
  };

  create = (data: CreateUpdateLessonDto) => {
    return this.apiClient.post({
      url: `/lessons`,
      data,
    });
  };

  update = (id: string, data: CreateUpdateLessonDto) => {
    return this.apiClient.put({
      url: `/lessons/${id}`,
      data,
    });
  };

  delete = (id: string) => {
    return this.apiClient.delete({
      url: `/lessons/${id}`,
    });
  };
}
