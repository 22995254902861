import DocumentFactory from './DocumentFactory';
import { UploadDocumentDto } from './dto/UploadDocumentDto';
import DocumentApiRepository from './repositories/api/DocumentApiRepository';

export default class DocumentsService {
  private documentApi: DocumentApiRepository;
  private documentFactory: DocumentFactory;

  constructor() {
    this.documentApi = new DocumentApiRepository();
    this.documentFactory = new DocumentFactory();
  }

  getDocument = (id: string) => {
    return this.documentApi.getDocument(id).then(response => {
      return this.documentFactory.createDocument(response.data);
    });
  };

  uploadDocument = (dto: UploadDocumentDto, file: File) => {
    return this.documentApi.uploadDocument(dto, file);
  };

  uploadDocumentWithUrl = (dto: UploadDocumentDto) => {
    return this.documentApi.uploadDocumentWithUrl(dto);
  };

  deleteDocument = (id: string) => {
    return this.documentApi.deleteDocument(id);
  };
}
