import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ApiLogsStore } from '../../stores/ApiLogsStore';

interface IFilterProps {
  isVisible: boolean;
  handleCloseModal: () => void;
  apiLogsStore: ApiLogsStore;
  handleSubmit: () => void;
}

export const FilterModal = (props: IFilterProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userId = data.get('userId')?.toString() ?? '';
    const originalUrl = data.get('originalUrl')?.toString() ?? '';
    const appPlatform = data.get('appPlatform')?.toString() ?? '';
    const appVersion = data.get('appVersion')?.toString() ?? '';
    const creationDate = data.get('creationDate')?.toString() ?? '';

    props.apiLogsStore.changeFilterForm({
      userId,
      originalUrl,
      appPlatform,
      appVersion,
      creationDate,
    });
    props.handleCloseModal();
    props.handleSubmit();
  };

  return (
    <Modal
      open={props.isVisible}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" noValidate onSubmit={handleSubmit} sx={style}>
        <TextField
          defaultValue={props.apiLogsStore.filterForm.userId}
          margin="normal"
          fullWidth
          id="userId"
          label="UserId"
          name="userId"
        />
        <TextField
          defaultValue={props.apiLogsStore.filterForm.originalUrl}
          margin="normal"
          fullWidth
          id="originalUrl"
          label="Url"
          name="originalUrl"
        />
        <TextField
          defaultValue={props.apiLogsStore.filterForm.appPlatform}
          margin="normal"
          fullWidth
          id="appPlatform"
          label="AppPlatform"
          name="appPlatform"
        />
        <TextField
          defaultValue={props.apiLogsStore.filterForm.appVersion}
          margin="normal"
          fullWidth
          id="appVersion"
          label="AppVersion"
          name="appVersion"
        />
        <TextField
          defaultValue={props.apiLogsStore.filterForm.creationDate}
          margin="normal"
          fullWidth
          id="creationDate"
          label="CreationDate"
          name="creationDate"
        />

        <Button type="submit" fullWidth variant={'contained'} sx={{ mt: 2, mb: 2 }}>
          Применить
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
