import { Box, Toolbar } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from './base/components/Loader';
import NavigationBar from './base/components/NavigationBar';
import { useRootStore } from './base/hooks/useRootStore';
import RequireAuth from './modules/auth/components/RequireAuth';
import LoginPage from './modules/auth/pages/LoginPage';
import InitPage from './modules/init/pages/InitPage';
import LecturesPage from './modules/lectures/pages/LecturesPage';
import LessonsPage from './modules/lessons/pages/LessonsPage';
import LessonCreateUpdatePage from './modules/lessons/pages/LessonCreateUpdatePage';
import QuestionCreateUpdate from './modules/questions/pages/QuestionCreateUpdate';
import QuestionsPage from './modules/questions/pages/QuestionsPage';
import TicketsPage from './modules/tickets/pages/TicketsPage';
import { ApiLogsPage } from './modules/api-logs/pages/ApiLogsPage';
import { ApiLogPage } from './modules/api-logs/pages/ApiLogPage';
import { TariffsPage } from './modules/tariffs/pages/TariffsPage';
import { UsersPage } from './modules/users/pages/UsersPage';
import { UserPage } from './modules/users/pages/UserPage';

const App = observer(() => {
  const rootStore = useRootStore();
  const { sync, currentUserStore } = rootStore;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await sync();
      await currentUserStore.getCurrentUser();
      setLoading(false);
    })();
  }, [sync]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <BrowserRouter>
        <NavigationBar />
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<InitPage />} />
            <Route
              path="/login"
              element={
                <RequireAuth>
                  <LoginPage />
                </RequireAuth>
              }
            />
            <Route
              path="/lectures"
              element={
                <RequireAuth>
                  <LecturesPage />
                </RequireAuth>
              }
            />
            <Route
              path="/lessons"
              element={
                <RequireAuth>
                  <LessonsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/lessons/create"
              element={
                <RequireAuth>
                  <LessonCreateUpdatePage />
                </RequireAuth>
              }
            />
            <Route
              path="/lessons/:lessonId/edit"
              element={
                <RequireAuth>
                  <LessonCreateUpdatePage />
                </RequireAuth>
              }
            />
            <Route
              path="/questions"
              element={
                <RequireAuth>
                  <QuestionsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/questions/create"
              element={
                <RequireAuth>
                  <QuestionCreateUpdate />
                </RequireAuth>
              }
            />
            <Route
              path="questions/:questionId/edit"
              element={
                <RequireAuth>
                  <QuestionCreateUpdate />
                </RequireAuth>
              }
            />
            <Route
              path="/tickets"
              element={
                <RequireAuth>
                  <TicketsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/api-logs"
              element={
                <RequireAuth>
                  <ApiLogsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/api-logs/:logId"
              element={
                <RequireAuth>
                  <ApiLogPage />
                </RequireAuth>
              }
            />
            <Route
              path="/tariffs"
              element={
                <RequireAuth>
                  <TariffsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />
            <Route
              path="/users/:id"
              element={
                <RequireAuth>
                  <UserPage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </Box>
  );
});

export default App;
