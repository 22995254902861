import { makeAutoObservable, runInAction } from 'mobx';
import Ticket from './model/Ticket';
import TicketService from './TicketService';

export default class TicketsStore {
  isLoading = true;
  tickets: Ticket[] = [];

  private ticketService: TicketService;

  constructor() {
    this.ticketService = new TicketService();
    makeAutoObservable(this);
  }

  init = () => {
    this.getAll();
  };

  getAll = () => {
    this.isLoading = true;
    this.ticketService
      .getTickets()
      .then(tickets => {
        runInAction(() => {
          this.tickets = tickets;
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  create = (num: number) => {
    this.isLoading = true;
    this.ticketService
      .createTicket({ num })
      .then(ticket => {
        runInAction(() => {
          this.tickets = this.ticketService.sort([...this.tickets, ticket]);
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  update = ({ id, num }: { id: string; num: number }) => {
    this.isLoading = true;
    this.ticketService
      .updateTicket(id, { num })
      .then(ticket => {
        runInAction(() => {
          const index = this.tickets.findIndex(l => l.id === id);
          if (index > -1) {
            this.tickets[index] = ticket;
          }
        });
      })
      .catch(error => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  delete = (id: string | null) => {
    if (!id) {
      return;
    }
    this.isLoading = true;
    this.ticketService
      .deleteTicket(id)
      .then(() => {
        runInAction(() => {
          this.tickets = this.tickets.filter(l => l.id !== id);
        });
      })
      .catch(error => {
        //@TODO aler error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}
