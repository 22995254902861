import AbstractApiRepository from '../../../../base/api/AbstractApiRepository';
import { CreateUpdateTicketDto } from '../../dto/CreateUpdateTicketDto';

export default class TicketApiRepository extends AbstractApiRepository {
  getTickets = () => {
    return this.apiClient.get({
      url: '/tickets',
    });
  };

  getTicket = (id: string) => {
    return this.apiClient.get({
      url: `/tickets/${id}`,
    });
  };

  createTicket = (data: CreateUpdateTicketDto) => {
    return this.apiClient.post({
      url: '/tickets',
      data,
    });
  };

  updateTicket = (id: string, data: CreateUpdateTicketDto) => {
    return this.apiClient.put({
      url: `/tickets/${id}`,
      data,
    });
  };

  deleteTicket = (id: string) => {
    return this.apiClient.delete({
      url: `/tickets/${id}`,
    });
  };
}
