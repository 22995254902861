import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import { CreateTariffDto } from '../../dto/CreateTariffDto';
import { TariffFieldsForm } from './TariffFieldsForm';

interface ICreateTariffModalProps {
  isVisible: boolean;
  handleCloseModal: () => void;
  handleSubmit: (dto: CreateTariffDto) => void;
}

export const CreateTariffModal = (props: ICreateTariffModalProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name')?.toString() ?? '';
    const duration = data.get('duration')?.toString() ?? '';
    const price = data.get('price')?.toString() ?? '';

    props.handleCloseModal();
    props.handleSubmit({
      name,
      duration: parseInt(duration),
      price: parseInt(price),
    });
  };

  return (
    <Modal
      open={props.isVisible}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" onSubmit={handleSubmit} sx={style}>
        <TariffFieldsForm />
        <Button type={'submit'} variant={'contained'} fullWidth>
          Создать
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
