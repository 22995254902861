import { DocumentType } from '../models/Document';
import { Accept } from 'react-dropzone';

export default class DocumentHelper {
  static mapDocumentTypeFilePickerAccept: {
    [key: string]: Accept;
  } = {
    [DocumentType.Video]: {
      'video/mp4': [],
    },
    [DocumentType.Image]: {
      'image/jpeg': [],
      'image/png': [],
    },
    [DocumentType.PdfDocument]: {
      'application/pdf': [],
    },
  };

  static urlStr = (id: string) => {
    return `${process.env.REACT_APP_API_URL}/documents/${id}/file`;
  };
}
