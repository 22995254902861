import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { SearchLogsDto } from '../dto/SearchLogsDto';

export class ApiLogsApiRepository extends AbstractApiRepository {
  getLogs = (data: SearchLogsDto) => {
    return this.apiClient.post<{ data: any[]; total: number }>({
      url: `/api-logger`,
      data,
    });
  };

  getLog = (id: string) => {
    return this.apiClient.get<any>({
      url: `/api-logger/${id}`,
    });
  };
}
