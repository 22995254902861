import { makeAutoObservable, runInAction } from 'mobx';
import Question from '../model/Question';
import QuestionService from '../QuestionService';

type RequestQuestions = (id: string) => Promise<Question[]>;

export default class QuestionsStore {
  isLoading = true;
  questions: Question[] = [];

  ticketId: string | null = null;
  lessonTestId: string | null = null;

  private questionService: QuestionService;

  constructor() {
    this.questionService = new QuestionService();
    makeAutoObservable(this);
  }

  init = ({ lessonTestId, ticketId }: { lessonTestId?: string | null; ticketId?: string | null }) => {
    this.ticketId = ticketId ?? null;
    this.lessonTestId = lessonTestId ?? null;
    this.getQuestions();
  };

  getQuestions = () => {
    let request: null | RequestQuestions = null;
    let id: string | null = null;
    if (this.ticketId) {
      id = this.ticketId;
      request = this.questionService.getByTicketId;
    }
    if (this.lessonTestId) {
      id = this.lessonTestId;
      request = this.questionService.getByLessonTestId;
    }
    if (!id || !request) {
      return;
    }

    this.isLoading = true;
    return request(id)
      .then(questions => {
        runInAction(() => {
          this.questions = questions;
        });
      })
      .catch(() => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  delete = (id: string) => {
    this.isLoading = true;
    return this.questionService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.questions = this.questions.filter(q => q.id !== id);
        });
      })
      .catch(() => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}
