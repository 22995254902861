import AbstractFactory from '../../base/AbstractFactory';
import Ticket from './model/Ticket';

export default class TicketFactory extends AbstractFactory {
  createTicket = (data: any) => {
    return this.create<Ticket>(Ticket, data);
  };

  createTickets = (data: any[]) => {
    return data.map(d => this.createTicket(d));
  };
}
