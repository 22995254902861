import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { SearchUsersDto } from '../dto/SearchUsersDto';
import { UpdateUserDto } from '../dto/UpdateUserDto';

export class UsersApiRepository extends AbstractApiRepository {
  getUsers = (data: SearchUsersDto) => {
    return this.apiClient.post<{ data: any[]; total: number }>({
      url: `/users/all`,
      data,
    });
  };

  getUser = (id: string) => {
    return this.apiClient.get<any>({
      url: `/users/${id}`,
    });
  };

  updateUser = (id: string, data: UpdateUserDto) => {
    return this.apiClient.put<any>({
      url: `/users/${id}`,
      data,
    });
  };
}
