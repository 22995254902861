import AbstractApiRepository from '../../../../base/api/AbstractApiRepository';
import { CreateUpdateLessonTestDto } from '../../dto/CreateUpdateLessonTestDto';

export default class LessonTestApiRepository extends AbstractApiRepository {
  getAll = () => {
    return this.apiClient.get({
      url: '/lesson-tests',
    });
  };

  get = (id: string) => {
    return this.apiClient.get({
      url: `/lesson-tests/${id}`,
    });
  };

  getByLessonId = (lessonId: string) => {
    return this.apiClient.get({
      url: `/lesson-tests?lessonId=${lessonId}`,
    });
  };

  create = (data: CreateUpdateLessonTestDto) => {
    return this.apiClient.post({
      url: '/lesson-tests',
      data,
    });
  };

  update = (id: string, data: CreateUpdateLessonTestDto) => {
    return this.apiClient.put({
      url: `/lesson-tests/${id}`,
      data,
    });
  };

  delete = (id: string) => {
    return this.apiClient.delete({
      url: `/lesson-tests/${id}`,
    });
  };
}
