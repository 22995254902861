import AbstractFactory from '../../base/AbstractFactory';
import QuestionFactory from '../questions/QuestionFactory';
import LessonTest from './model/LessonTest';

export default class LessonTestFactory extends AbstractFactory {
  // private questionFactory: QuestionFactory;

  // constructor() {
  //   super();
  //   this.questionFactory = new QuestionFactory();
  // }

  createLessonTest = (data: any) => {
    const res = this.create<LessonTest>(LessonTest, data);
    // res.questions = this.questionFactory.createQuestions(data?.questions ?? []);

    return res;
  };

  createLessonTests = (data: any[]) => {
    return data.map(d => this.createLessonTest(d));
  };
}
