import { UserService } from '../UserService';

export class CurrentUserStore {
  private userService: UserService;

  constructor() {
    this.userService = new UserService();
  }

  getCurrentUser = () => {
    return this.userService.getCurrentUser().catch(() => {
      //@TODO alert error
    });
  };
}
