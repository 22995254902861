import { Box, Button, Checkbox, DialogContent, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { UpdateTariffDto } from '../../dto/UpdateTariffDto';
import { TariffFieldsForm } from './TariffFieldsForm';
import { Tariff } from '../../models/Tariff';

interface IUpdateTariffModalProps extends IContentModalProps {
  isVisible: boolean;
}

export const UpdateTariffModal = (props: IUpdateTariffModalProps) => {
  return (
    <Modal
      open={props.isVisible}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        <ContentModal {...props} />
      </DialogContent>
    </Modal>
  );
};

interface IContentModalProps {
  handleCloseModal: () => void;
  handleSubmit: (dto: UpdateTariffDto) => void;
  tariff: Tariff | null;
}

const ContentModal = React.forwardRef((props: IContentModalProps, ref) => {
  const [isActive, setActive] = useState(props.tariff?.isActive ?? false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name')?.toString() ?? '';
    const duration = data.get('duration')?.toString() ?? '';
    const price = data.get('price')?.toString() ?? '';

    props.handleSubmit({
      id: props.tariff?.id ?? '',
      name,
      duration: parseInt(duration),
      price: parseInt(price),
      isActive,
    });
    props.handleCloseModal();
  };

  return (
    <Box ref={ref} component="form" onSubmit={handleSubmit} sx={style}>
      <TariffFieldsForm
        name={props.tariff?.name ?? undefined}
        duration={props.tariff?.duration ?? undefined}
        price={props.tariff?.price ?? undefined}
      />
      <Box flexDirection={'row'} justifyContent={'space-between'}>
        <Typography display={'inline'}>Активен</Typography>
        <Checkbox checked={isActive} onChange={(event, checked) => setActive(checked)} />
      </Box>
      <Button type={'submit'} variant={'contained'} fullWidth>
        Сохранить
      </Button>
    </Box>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
