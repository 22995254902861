import AbstractModel from '../../../base/AbstractModel';

export class ApiLog extends AbstractModel {
  id: string | null = null;
  statusCode: number | null = null; // show
  method: string | null = null; // show
  originalUrl: string | null = null; // show
  body: string | null = null;
  responseData: string | null = null;
  userId: string | null = null; // show
  responseTime: string | null = null;
  appPlatform: string | null = null; // show
  appVersion: string | null = null; // show
  creationDate: string | null = null; // show

  constructor(props: any) {
    super();
    this.load(props);
  }
}
