import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../base/components/Loader';
import { useQuery } from '../../../base/hooks/useQuery';
import LessonsStore from '../stores/LessonsStore';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';
import Lesson from '../models/Lesson';

type ModalState = {
  isVisible: boolean;
  lesson: Lesson | null;
};

const LessonsPage = observer(() => {
  const query = useQuery();
  const lectureId = query.get('lectureId');
  const navigate = useNavigate();
  const lessonsStore = useLocalObservable(() => new LessonsStore());
  const { isLoading, lessons } = lessonsStore;
  const { init, createTest } = lessonsStore;

  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState<ModalState>({
    isVisible: false,
    lesson: null,
  });

  useEffect(() => {
    init({ lectureId: lectureId ?? '' });
  }, [init, lectureId]);

  const openConfirmDeleteModal = (lesson: Lesson) =>
    setConfirmDeleteModalState({
      isVisible: true,
      lesson: lesson,
    });
  const closeConfirmDeleteModal = () =>
    setConfirmDeleteModalState({
      isVisible: false,
      lesson: null,
    });
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.lesson?.id) {
      lessonsStore.delete(confirmDeleteModalState.lesson.id);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant="contained" onClick={() => navigate(`/lessons/create?lectureId=${lectureId}`)}>
        Создать
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {lessons.map((lesson, index) => (
          <ListItem divider key={lesson.id}>
            <ListItemText primary={`${index + 1}. ${lesson.name}`} />
            <ListItemSecondaryAction>
              <Button
                onClick={() => {
                  if (lesson.lessonTestId) {
                    return navigate(`/questions?lessonTestId=${lesson.lessonTestId}`);
                  }
                  createTest({
                    lessonId: lesson.id ?? '',
                    onSuccess: (lessonTestId) => navigate(`/questions?lessonTestId=${lessonTestId}`),
                  });
                }}
                sx={{ mr: '16px' }}
                variant="outlined"
              >
                Тест
              </Button>
              <IconButton
                sx={{ mr: '16px' }}
                onClick={() => navigate(`/lessons/${lesson.id}/edit?lectureId=${lectureId}`)}
                aria-label="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                sx={{ mr: '16px' }}
                onClick={(event) => {
                  openConfirmDeleteModal(lesson);
                }}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
});

export default LessonsPage;
