import { UserApiRepository } from './repositories/UserApiRepository';

export class UserService {
  private apiRepository: UserApiRepository;

  constructor() {
    this.apiRepository = new UserApiRepository();
  }

  getCurrentUser = () => {
    return this.apiRepository.getCurrentUser();
  };
}
