import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { CreateTariffDto } from '../dto/CreateTariffDto';
import { UpdateTariffDto } from '../dto/UpdateTariffDto';

export class TariffApiRepository extends AbstractApiRepository {
  getTariffs = () => {
    return this.apiClient.get<any[]>({
      url: `/tariffs/all`,
    });
  };

  getTariff = (id: string) => {
    return this.apiClient.get<any>({
      url: `/tariffs/${id}`,
    });
  };

  createTariff = (data: CreateTariffDto) => {
    return this.apiClient.post<any>({
      url: `/tariffs`,
      data,
    });
  };

  updateTariff = (id: string, data: UpdateTariffDto) => {
    return this.apiClient.put<any>({
      url: `/tariffs/${id}`,
      data,
    });
  };
}
