import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { CreateLectureDto } from '../dto/CreateLectureDto';

class LecturesApiRepository extends AbstractApiRepository {
  getAll = () => {
    return this.apiClient.get({
      url: '/lectures',
    });
  };

  getById = (id: string) => {
    return this.apiClient.get({
      url: `/lectures/${id}`,
    });
  };

  create = (data: CreateLectureDto) => {
    return this.apiClient.post({
      url: '/lectures',
      data,
    });
  };

  update = (id: string, data: CreateLectureDto) => {
    return this.apiClient.put({
      url: `/lectures/${id}`,
      data,
    });
  };

  delete = (id: string) => {
    return this.apiClient.delete({
      url: `/lectures/${id}`,
    });
  };
}

export default LecturesApiRepository;
