import LocalStorageClient from './LocalStorageClient';
import { EncryptStorage } from 'encrypt-storage';

export class EncryptedLocalStorageClient extends LocalStorageClient {
  storage: EncryptStorage;

  constructor() {
    super();
    this.storage = new EncryptStorage(process.env.REACT_APP_ENCRYPT_STORAGE_KEY!);
  }

  get = async (tableName: string): Promise<any> => {
    const data = await this.storage.getItem(tableName);

    return data ? data : null;
  };

  set = async (tableName: string, data: any): Promise<any> => {
    return this.storage.setItem(tableName, JSON.stringify(data));
  };

  removeAll = async (tableName: string): Promise<any> => {
    return this.storage.removeItem(tableName);
  };
}
