import { UserTariffApiRepository } from './repositories/UserTariffApiRepository';
import { UserTariffsFactory } from './UserTariffsFactory';
import { CreateUserTariffDto } from './dto/CreateUserTariffDto';

export class UserTariffService {
  private apiRepository: UserTariffApiRepository;
  private factory: UserTariffsFactory;

  constructor() {
    this.apiRepository = new UserTariffApiRepository();
    this.factory = new UserTariffsFactory();
  }

  create = async (dto: CreateUserTariffDto) => {
    const { data } = await this.apiRepository.create(dto);

    return this.factory.createUserTariff(data);
  };

  delete = (id: string) => {
    return this.apiRepository.delete(id);
  };
}
