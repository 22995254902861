import AbstractApiRepository from '../../../../base/api/AbstractApiRepository';
import { CreateUpdateQuestionDto } from '../../dto/CreateUpdateQuestionDto';

export default class QuestionApiResository extends AbstractApiRepository {
  get = (id: string) => {
    return this.apiClient.get({
      url: `/questions/${id}`,
    });
  };

  getByLessonTestId = (lessonTestId: string) => {
    return this.apiClient.get({
      url: `/questions?lessonTestId=${lessonTestId}`,
    });
  };

  getByTicketId = (ticketId: string) => {
    return this.apiClient.get({
      url: `/questions?ticketId=${ticketId}`,
    });
  };

  create = (data: CreateUpdateQuestionDto) => {
    return this.apiClient.post({
      url: '/questions',
      data,
    });
  };

  update = (id: string, data: CreateUpdateQuestionDto) => {
    return this.apiClient.put({
      url: `/questions/${id}`,
      data,
    });
  };

  delete = (id: string) => {
    return this.apiClient.delete({
      url: `/questions/${id}`,
    });
  };
}
