import AbstractFactory from '../../base/AbstractFactory';
import DocumentFactory from '../documents/DocumentFactory';
import Question from './model/Question';

export default class QuestionFactory extends AbstractFactory {
  private documentFactory: DocumentFactory;

  constructor() {
    super();
    this.documentFactory = new DocumentFactory();
  }

  createQuestion = (data: any) => {
    const res = this.create<Question>(Question, data);
    res.documents = this.documentFactory.createDocuments(data?.documents ?? []);

    return res;
  };

  createQuestions = (data: any[]) => {
    return data.map(d => this.createQuestion(d));
  };
}
