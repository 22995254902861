import { LocalClientTypes } from './LocalClientTypes';
import LocalStorageClient from './localStorage/LocalStorageClient';
import ILocalClient from './ILocalClient';
import { EncryptedLocalStorageClient } from './localStorage/EncryptedLocalStorageClient';

export default abstract class AbstractLocalRepository<T extends {} = any> {
  localClient!: ILocalClient;

  public static clients = {
    [LocalClientTypes.localStorage]: new LocalStorageClient(),
    [LocalClientTypes.encryptedLocalStorage]: new EncryptedLocalStorageClient(),
  };

  constructor(localClientType = LocalClientTypes.localStorage) {
    this.setLocalClient(AbstractLocalRepository.clients[localClientType]);
  }

  setLocalClient = (localClient: ILocalClient) => {
    this.localClient = localClient;
  };

  abstract tableName(): string;

  get = async (): Promise<T | null> => {
    return await this.localClient.get(this.tableName());
  };

  set = async (data: T) => {
    return await this.localClient.set(this.tableName(), data);
  };

  update = async (data: T) => {
    return await this.localClient.update(this.tableName(), data);
  };

  removeAll = async () => {
    return await this.localClient.removeAll(this.tableName());
  };
}
