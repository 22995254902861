import { Box, Button, Modal, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';

type ICreateUpdateAnswerModalProps = {
  isVisible: boolean;
  close: () => void;
  answer: string | null;
  index: number | null;
  onCreate: (answer: string) => void;
  onUpdate: (index: number, answer: string) => void;
};

const CreateUpdateAnswerModal = observer(
  ({ answer, index, isVisible, close, onCreate, onUpdate }: ICreateUpdateAnswerModalProps) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const answerForm = data.get('answer')?.toString() ?? '';

      close();
      if (!answer) {
        return onCreate(answerForm);
      }
      if (index === null) {
        return;
      }

      return onUpdate(index, answerForm);
    };

    return (
      <Modal
        open={isVisible}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={'form'} onSubmit={handleSubmit} sx={style}>
          <TextField
            margin="normal"
            defaultValue={answer}
            required
            fullWidth
            multiline
            maxRows={3}
            id="answer"
            label="Ответ"
            name="answer"
            autoFocus
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {answer ? 'Сохранить' : 'Создать'}
          </Button>
        </Box>
      </Modal>
    );
  },
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default CreateUpdateAnswerModal;
