import { makeAutoObservable, runInAction } from 'mobx';
import { MapFile } from '../../../types/MapFile';
import DocumentsService from '../../documents/DocumentsService';
import DocumentHelper from '../../documents/helpers/DocumentsHelper';
import { DocumentType, EntityType } from '../../documents/models/Document';
import { CreateUpdateLessonDto } from '../dto/CreateUpdateLessonDto';
import LessonsService from '../LessonsService';
import Lesson from '../models/Lesson';
import CreateUpdateLessonForm from '../types/CreateUpdateLessonForm';

export default class LessonCreateEditStore {
  isLoading = true;

  private lessonsService: LessonsService;
  private documentsService: DocumentsService;

  constructor() {
    this.lessonsService = new LessonsService();
    this.documentsService = new DocumentsService();
    makeAutoObservable(this);
  }

  private lessonId: string | null = null;
  lesson: Lesson | null = null;
  files: Map<string, MapFile[]> = new Map();
  deleteFileIds: string[] = [];

  private onSuccess?: () => void;

  init = ({ lessonId }: { lessonId?: string | null }) => {
    this.lessonId = lessonId ?? null;
    this.getLesson();
  };

  submit = (form: CreateUpdateLessonForm, onSuccess: () => void) => {
    this.onSuccess = onSuccess;
    if (this.lessonId) {
      return this.update(form);
    }
    this.create(form);
  };

  getLesson = () => {
    if (!this.lessonId) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    return this.lessonsService
      .getLesson(this.lessonId)
      .then((lesson) => {
        runInAction(() => {
          lesson.documents.forEach((d) => {
            if (d.documentType && d.id) {
              const files = this.files.get(d.documentType) ?? [];
              files.push({
                id: d.id,
                documentType: d.documentType,
                url: DocumentHelper.urlStr(d.id),
                documentName: d.name,
              });
              this.files.set(d.documentType, files);
            }
          });
          this.lesson = lesson;
        });
      })
      .catch((error) => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  private formToDto = (form: CreateUpdateLessonForm): CreateUpdateLessonDto => {
    return {
      name: form.name,
      lectureId: form.lectureId,
      textFz: form.textFz,
      textKoap: form.textKoap,
      textPdd: form.textPdd,
      sort: this.lesson?.sort ?? undefined,
    };
  };

  create = (form: CreateUpdateLessonForm) => {
    this.isLoading = true;
    return this.lessonsService
      .create(this.formToDto(form))
      .then((lesson) => {
        this.lessonId = lesson.id;
        this.uploadDeleteFiles();
      })
      .catch(() => {
        runInAction(() => {
          this.isLoading = false;
        });
        //@TODO alert error
      });
  };

  update = (form: CreateUpdateLessonForm) => {
    if (!this.lessonId) {
      return;
    }
    this.isLoading = true;
    return this.lessonsService
      .update(this.lessonId, this.formToDto(form))
      .then(() => {
        this.uploadDeleteFiles();
      })
      .catch(() => {
        runInAction(() => {
          this.isLoading = false;
        });
        //@TODO alert error
      });
  };

  uploadDeleteFiles = () => {
    if (this.files.size === 0 && this.deleteFileIds.length === 0) {
      return this.onSuccess && this.onSuccess();
    }
    return Promise.all(this.deleteFileIds.map((id) => this.documentsService.deleteDocument(id)))
      .then(() => {
        const newFiles = Array.from(this.files.values()).reduce(
          (previous, current) => [...previous, ...current.filter((f) => !f.id)],
          [],
        );
        return Promise.all(newFiles.map((f) => this.uploadFile(f)))
          .then(() => {
            return this.onSuccess && this.onSuccess();
          })
          .catch(() => {
            //@TODO alert error
          });
      })
      .catch(() => {
        //@TODO alert error
      });
  };

  uploadFile = (mapFile: MapFile): Promise<any> => {
    if (!this.lessonId || !mapFile.file) {
      return Promise.resolve();
    }
    return this.documentsService.uploadDocument(
      {
        entityId: this.lessonId,
        entityType: EntityType.Lesson,
        documentType: mapFile.documentType,
        documentName: mapFile.documentName ?? undefined,
      },
      mapFile.file,
    );
  };

  onSelectFile = (documentType: DocumentType, file: File, documentName?: string) => {
    const files = this.files.get(documentType) ?? [];
    // if (this.files.get(documentType)?.id) {
    //   this.deleteFileIds.push(this.files.get(documentType)!.id!);
    // }
    files.push({
      url: URL.createObjectURL(file),
      file,
      documentType,
      documentName,
    });
    this.files.set(documentType, files);
  };

  deleteFile = (documentType: DocumentType, index: number) => {
    const files = this.files.get(documentType) ?? [];
    const file = files[index];
    if (file?.id) {
      this.deleteFileIds.push(file!.id!);
    }
    files.splice(index, 1);
    this.files.set(documentType, files);
  };
}
