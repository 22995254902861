import { makeAutoObservable, runInAction } from 'mobx';
import { ApiLog } from '../models/ApiLog';
import { ApiLogsService } from '../ApiLogsService';
import { LIMIT } from '../consts/ApiLogsConsts';
import { ApiLogsFilter, IApiLogsFilter } from '../forms/ApiLogsFilter';

export class ApiLogsStore {
  loading = false;
  logs: ApiLog[] = [];
  total = 0;

  filterForm = ApiLogsFilter;

  private apiLogsService: ApiLogsService;

  constructor() {
    makeAutoObservable(this);
    this.apiLogsService = new ApiLogsService();
  }

  changeFilterForm = (form: IApiLogsFilter) => {
    this.filterForm = form;
  };

  getLogs = (page = 0) => {
    this.setLoading(true);

    return this.apiLogsService
      .getLogs({
        limit: LIMIT,
        skip: page * LIMIT,
        ...this.filterForm,
      })
      .then(({ logs, total }) => {
        runInAction(() => {
          this.logs = logs;
          this.total = total;
        });
      })
      .catch(() => {
        // TODO alert error
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
