import AbstractFactory from '../../base/AbstractFactory';
import Document from './models/Document';

export default class DocumentFactory extends AbstractFactory {
  createDocument = (data: any) => {
    return this.create<Document>(Document, data);
  };

  createDocuments = (data: any[]) => {
    return data.map(d => this.createDocument(d));
  };
}
