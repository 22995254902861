import AbstractFactory from '../../base/AbstractFactory';
import { UserTariff } from './models/UserTariff';
import { TariffFactory } from '../tariffs/TariffFactory';

export class UserTariffsFactory extends AbstractFactory {
  private tariffFactory: TariffFactory;

  constructor() {
    super();
    this.tariffFactory = new TariffFactory();
  }

  createUserTariff = (json: any) => {
    const userTariff = this.create<UserTariff>(UserTariff, json);
    userTariff.tariff = userTariff.tariff ? this.tariffFactory.createTariff(userTariff.tariff) : null;

    return userTariff;
  };

  createUserTariffs = (json: any[]) => {
    return json?.map((j) => this.createUserTariff(j)) ?? [];
  };
}
