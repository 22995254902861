import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalObservable } from 'mobx-react';
import { TariffsStore } from '../../../tariffs/stores/TariffsStore';
import { Button, Dialog, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Tariff } from '../../../tariffs/models/Tariff';

interface ITariffsModalProps {
  isVisible: boolean;
  handleCloseModal: () => void;
  handleSubmit: (tariff: Tariff) => void;
}

export const TariffsModal = observer((props: ITariffsModalProps) => {
  const tariffsStore = useLocalObservable(() => new TariffsStore());

  const [selected, setSelected] = useState<Tariff | null>(null);

  useEffect(() => {
    if (props.isVisible) {
      tariffsStore.getTariffs();
    }
  }, [props.isVisible]);

  const handleSubmit = () => {
    if (selected) {
      props.handleSubmit(selected);
      props.handleCloseModal();
    }
  };

  return (
    <Dialog onClose={props.handleCloseModal} open={props.isVisible}>
      <List sx={{ pt: 0 }}>
        {tariffsStore.tariffs.map((tariff, index) => (
          <ListItem selected={tariff.id === selected?.id}>
            <ListItemButton onClick={() => setSelected(tariff)} key={`tariff_${tariff.id ?? index}`}>
              <ListItemText primary={`${tariff.name} ${tariff.price}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button variant={'contained'} sx={{ mb: 2, mr: 2, ml: 2 }} onClick={handleSubmit}>
        Добавить
      </Button>
    </Dialog>
  );
});
