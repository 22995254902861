import { CreateUpdateTicketDto } from './dto/CreateUpdateTicketDto';
import Ticket from './model/Ticket';
import TicketApiRepository from './repositories/api/TicketApiRepository';
import TicketFactory from './TicketFactory';

export default class TicketService {
  private ticketFactory: TicketFactory;
  private ticketApi: TicketApiRepository;

  constructor() {
    this.ticketFactory = new TicketFactory();
    this.ticketApi = new TicketApiRepository();
  }

  getTickets = () => {
    return this.ticketApi.getTickets().then(response => {
      return this.ticketFactory.createTickets(response.data as any[]);
    });
  };

  getTicket = (id: string) => {
    return this.ticketApi.getTicket(id).then(response => {
      return this.ticketFactory.createTicket(response.data);
    });
  };

  createTicket = (dto: CreateUpdateTicketDto) => {
    return this.ticketApi.createTicket(dto).then(response => {
      return this.ticketFactory.createTicket(response.data);
    });
  };

  updateTicket = (id: string, dto: CreateUpdateTicketDto) => {
    return this.ticketApi.updateTicket(id, dto).then(response => {
      return this.ticketFactory.createTicket(response.data);
    });
  };

  deleteTicket = (id: string) => {
    return this.ticketApi.deleteTicket(id);
  };

  sort = (lectures: Ticket[]) => {
    return lectures.sort((a, b) => (a.num ?? 0) - (b.num ?? 0));
  };
}
