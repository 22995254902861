import AbstractModel from '../../../base/AbstractModel';
// import Question from '../../questions/model/Question';

export default class LessonTest extends AbstractModel {
  id: string | null = null;
  // questions: Question[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
