import LessonTestFactory from './LessonTestFactory';
import LessonTestApiRepository from './repositories/api/LessonTestApiRepository';

export default class LessonTestService {
  private lessonTestApi: LessonTestApiRepository;
  private lessonTestFactory: LessonTestFactory;

  constructor() {
    this.lessonTestApi = new LessonTestApiRepository();
    this.lessonTestFactory = new LessonTestFactory();
  }

  getAll = () => {
    return this.lessonTestApi.getAll().then(response => {
      return this.lessonTestFactory.createLessonTests(response.data as any[]);
    });
  };

  get = (id: string) => {
    return this.lessonTestApi.get(id).then(response => {
      return this.lessonTestFactory.createLessonTest(response.data);
    });
  };

  getByLessonId = (lessonId: string) => {
    return this.lessonTestApi.getByLessonId(lessonId).then(response => {
      const lessonTests = this.lessonTestFactory.createLessonTests(response.data as any[]);
      if (lessonTests.length > 0) {
        return lessonTests[0];
      }

      return null;
    });
  };

  create = (lessonId: string) => {
    return this.lessonTestApi.create({ lessonId }).then(response => {
      return this.lessonTestFactory.createLessonTest(response.data);
    });
  };

  update = (id: string, lessonId: string) => {
    return this.lessonTestApi.update(id, { lessonId }).then(response => {
      return this.lessonTestFactory.createLessonTest(response.data);
    });
  };

  delete = (id: string) => {
    return this.lessonTestApi.delete(id);
  };
}
