import { AxiosRequestConfig } from 'axios';
import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import ILoginRequest from '../types/ILoginRequest';
import IRefreshRequest from '../types/IRefreshRequest';
import ITokens from '../types/ITokens';

export default class AuthApiRepository extends AbstractApiRepository {
  login = (data: ILoginRequest) => {
    return this.apiClient.post<ITokens>({
      url: '/auth/login',
      data,
    });
  };

  logout = () => {
    return this.apiClient.post({
      url: '/auth/logout',
    });
  };

  refreshToken = (data: IRefreshRequest) => {
    return this.apiClient.post<ITokens>({
      url: '/auth/refresh',
      data,
    });
  };

  setAccessToken = (accessToken: string) => {
    return this.apiClient.setAccessToken(accessToken);
  };

  clearAccessToken = () => {
    return this.apiClient.clearAccessToken();
  };

  setOnUnauthenticated = (func: (priginalRequest: AxiosRequestConfig) => void) => {
    return this.apiClient.setOnUnauthenticated(func);
  };
}
