import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import { Table } from '../../common/components/Table';
import { Pagination } from '../../common/components/Pagination';
import { useLocalStore } from 'mobx-react';
import { UsersStore } from '../stores/UsersStore';
import { LIMIT, TableColumns } from '../consts/UsersConsts';
import { FilterModal } from './components/FilterModal';
import { useNavigate } from 'react-router-dom';

export const UsersPage = observer(() => {
  const navigate = useNavigate();
  const usersStore = useLocalStore(() => new UsersStore());

  const [page, setPage] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    usersStore.getUsers();
  }, []);

  const handleOpenFilter = () => setFilterVisible(true);

  const handleCloseFilter = () => setFilterVisible(false);

  const handleSubmitFilter = () => {
    usersStore.getUsers();
  };

  const handlePageClick = (page: number) => {
    setPage(page);
    usersStore.getUsers(page);
  };

  const handleDoubleClick = (index: number) => {
    navigate(`/users/${usersStore.users[index].id}`);
  };

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant={'contained'} onClick={handleOpenFilter}>
        Фильтр
      </Button>
      <Table data={usersStore.users} columns={TableColumns} handleDoubleClick={handleDoubleClick} />
      <Pagination page={page} limit={LIMIT} total={usersStore.total} handlePageClick={handlePageClick} />
      <FilterModal
        isVisible={filterVisible}
        handleCloseModal={handleCloseFilter}
        usersStore={usersStore}
        handleSubmit={handleSubmitFilter}
      />
    </div>
  );
});
