import AbstractModel from '../../../base/AbstractModel';
import { UserTariff } from '../../user-tariffs/models/UserTariff';

export class User extends AbstractModel {
  id: string | null = null;
  lastName: string | null = null;
  name: string | null = null;
  patronymic: string | null = null;
  phone: string | null = null;
  verified: boolean | null = null;
  userTariffs: UserTariff[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
