import React from 'react';
import AuthStore from '../modules/auth/AuthStore';
import { CurrentUserStore } from '../modules/user/stores/CurrentUserStore';

class RootStore {
  authStore: AuthStore;
  currentUserStore: CurrentUserStore;

  constructor() {
    this.authStore = new AuthStore();
    this.currentUserStore = new CurrentUserStore();
  }

  sync = async () => {
    await Promise.all(
      Object.values(this).map((store) => {
        return store?.sync ? store?.sync() : Promise.resolve();
      }),
    );
  };

  clear = async () => {
    await Promise.all(
      Object.values(this).map((store) => {
        return store?.clearStore ? store?.clearStore() : Promise.resolve();
      }),
    );
  };
}

const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
