import { makeAutoObservable, runInAction } from 'mobx';
import AuthService from './AuthService';

export default class AuthStore {
  isAuthorized = false;

  private authService = new AuthService();

  constructor() {
    makeAutoObservable(this);
  }

  sync = async () => {
    const localData = await this.authService.loadLocalData();
    this.isAuthorized = !!localData?.accessToken;
    if (this.isAuthorized) {
      this.setRefreshTokenEvent();
    }
  };

  login = ({ phone, password, onSuccess }: { phone?: string; password?: string; onSuccess: () => void }) => {
    if (!phone || !password) {
      return;
    }
    return this.authService
      .login({
        phone,
        password,
      })
      .then((tokens) => {
        this.isAuthorized = true;
        this.setRefreshTokenEvent();
        onSuccess();
      })
      .catch((error) => {
        // console.log(error);
        //@TODO alert error
      });
  };

  setRefreshTokenEvent = () => {
    return this.authService.setRefreshTokenEvent(() => {
      this.isAuthorized = false;
    });
  };

  logout = () => {
    return this.authService
      .logout()
      .catch(() => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isAuthorized = false;
        });
      });
  };
}
