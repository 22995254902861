import AbstractLocalRepository from '../../../base/db/AbstractLocalRepository';
import { LocalClientTypes } from '../../../base/db/LocalClientTypes';
import ITokens from '../types/ITokens';

export default class AuthLocalRepository extends AbstractLocalRepository<ITokens> {
  constructor(localClientType = LocalClientTypes.encryptedLocalStorage) {
    super(localClientType);
    this.setLocalClient(AbstractLocalRepository.clients[LocalClientTypes.encryptedLocalStorage]);
  }

  tableName(): string {
    return 'userTokens';
  }
}
