import React, { useEffect, useState } from 'react';
import { useLocalObservable } from 'mobx-react';
import { ApiLogsStore } from '../stores/ApiLogsStore';
import Loader from '../../../base/components/Loader';
import { observer } from 'mobx-react-lite';
import { LIMIT, TableColumns } from '../consts/ApiLogsConsts';
import { useNavigate } from 'react-router-dom';
import { FilterModal } from './components/FilterModal';
import { Button } from '@mui/material';
import { Pagination } from '../../common/components/Pagination';
import { Table } from '../../common/components/Table';

export const ApiLogsPage = observer(() => {
  const navigate = useNavigate();
  const apiLogsStore = useLocalObservable(() => new ApiLogsStore());

  const [page, setPage] = useState(0);
  const [modalFilterVisible, setModalFilterVisible] = useState(false);

  useEffect(() => {
    apiLogsStore.getLogs();
  }, []);

  const handlePageClick = (page: number) => {
    setPage(page);
    apiLogsStore.getLogs(page);
  };

  const handleDoubleClick = (index: number) => {
    navigate(`/api-logs/${apiLogsStore.logs[index].id}`);
  };

  const handleOpenFilter = () => setModalFilterVisible(true);

  const handleCloseFilter = () => setModalFilterVisible(false);

  const handleSubmitFilter = () => {
    apiLogsStore.getLogs();
  };

  if (apiLogsStore.loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button variant={'contained'} onClick={handleOpenFilter}>
        Фильтр
      </Button>
      <Table data={apiLogsStore.logs} columns={TableColumns} handleDoubleClick={handleDoubleClick} />
      <Pagination page={page} limit={LIMIT} total={apiLogsStore.total} handlePageClick={handlePageClick} />
      <FilterModal
        isVisible={modalFilterVisible}
        apiLogsStore={apiLogsStore}
        handleCloseModal={handleCloseFilter}
        handleSubmit={handleSubmitFilter}
      />
    </div>
  );
});
