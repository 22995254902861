import { CreateUpdateQuestionDto } from './dto/CreateUpdateQuestionDto';
import Question from './model/Question';
import QuestionFactory from './QuestionFactory';
import QuestionApiResository from './repositories/api/QuestionApiRepository';

export default class QuestionService {
  private questionApi: QuestionApiResository;
  private questionFactory: QuestionFactory;

  constructor() {
    this.questionApi = new QuestionApiResository();
    this.questionFactory = new QuestionFactory();
  }

  getByLessonTestId = (lessonTestId: string) => {
    return this.questionApi.getByLessonTestId(lessonTestId).then(response => {
      const questions = this.questionFactory.createQuestions(response.data as any[]);

      return this.sort(questions);
    });
  };

  getByTicketId = (ticketId: string) => {
    return this.questionApi.getByTicketId(ticketId).then(response => {
      const questions = this.questionFactory.createQuestions(response.data as any[]);

      return this.sort(questions);
    });
  };

  get = (id: string) => {
    return this.questionApi.get(id).then(response => {
      return this.questionFactory.createQuestion(response.data);
    });
  };

  create = (dto: CreateUpdateQuestionDto) => {
    return this.questionApi.create(dto).then(response => {
      return this.questionFactory.createQuestion(response.data);
    });
  };

  update = (id: string, dto: CreateUpdateQuestionDto) => {
    return this.questionApi.update(id, dto).then(response => {
      return this.questionFactory.createQuestion(response.data);
    });
  };

  delete = (id: string) => {
    return this.questionApi.delete(id);
  };

  sort = (questions: Question[]) => {
    return questions.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
  };
}
