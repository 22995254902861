import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useLocalObservable } from 'mobx-react';
import { ApiLogStore } from '../stores/ApiLogStore';
import Loader from '../../../base/components/Loader';
import { Field } from '../../common/components/Field';

export const ApiLogPage = observer(() => {
  const { logId } = useParams();
  const apiLogStore = useLocalObservable(() => new ApiLogStore());

  useEffect(() => {
    if (logId) {
      apiLogStore.getLog(logId);
    }
  }, []);

  const apiLog = apiLogStore.apiLog;

  if (!apiLog || apiLogStore.loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Field label={'statusCode'} value={apiLog.statusCode} />
      <Field label={'method'} value={apiLog.method} />
      <Field label={'originalUrl'} value={apiLog.originalUrl} />
      <Field label={'appPlatform'} value={apiLog.appPlatform} />
      <Field label={'appVersion'} value={apiLog.appVersion} />
      <Field label={'body'} value={apiLog.body} />
      <Field label={'responseData'} value={apiLog.responseData} />
      <Field label={'responseTime'} value={apiLog.responseTime} />
      <Field label={'creationDate'} value={apiLog.creationDate} />
    </div>
  );
});
