import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useLocalObservable } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../base/hooks/useQuery';
import QuestionsStore from '../stores/QuestionsStore';
import Question from '../model/Question';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';

type ConfirmDeleteModalState = {
  isVisible: boolean;
  question: Question | null;
};

const InitConfirmDeleteModalState: ConfirmDeleteModalState = {
  isVisible: false,
  question: null,
};

const QuestionsPage = observer(() => {
  const navigate = useNavigate();
  const query = useQuery();
  const lessonTestId = query.get('lessonTestId');
  const ticketId = query.get('ticketId');
  const questionsStore = useLocalObservable(() => new QuestionsStore());
  const { questions } = questionsStore;
  const { init } = questionsStore;

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>(InitConfirmDeleteModalState);

  useEffect(() => {
    init({ lessonTestId, ticketId });
  }, [init, lessonTestId, ticketId]);

  const openConfirmDeleteModal = (question: Question) =>
    setConfirmDeleteModalState({
      isVisible: true,
      question,
    });
  const closeConfirmDeleteModal = () => setConfirmDeleteModalState(InitConfirmDeleteModalState);
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.question?.id) {
      questionsStore.delete(confirmDeleteModalState.question.id);
    }
  };

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          if (lessonTestId) {
            return navigate(`/questions/create?lessonTestId=${lessonTestId}`);
          }
          if (ticketId) {
            return navigate(`/questions/create?ticketId=${ticketId}`);
          }
        }}
      >
        Создать
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {questions.map((question, index) => (
          <ListItem divider key={question.id}>
            <ListItemText style={{ flex: 4 }} primary={`${index + 1}. ${question.questionText}`} />
            <Box style={{ flex: 1 }}>
              <ListItemSecondaryAction>
                <IconButton
                  sx={{ mr: '16px' }}
                  onClick={() => {
                    if (lessonTestId) {
                      return navigate(`/questions/${question.id}/edit?lessonTestId=${lessonTestId}`);
                    }
                    if (ticketId) {
                      return navigate(`/questions/${question.id}/edit?ticketId=${ticketId}`);
                    }
                  }}
                  aria-label="edit"
                >
                  <Edit />
                </IconButton>
                {!!question.id && (
                  <IconButton
                    sx={{ mr: '16px' }}
                    onClick={(event) => {
                      openConfirmDeleteModal(question);
                    }}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </Box>
          </ListItem>
        ))}
      </List>
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
});

export default QuestionsPage;
