import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useLocalObservable } from 'mobx-react';
import { UserStore } from '../stores/UserStore';
import { Field } from '../../common/components/Field';
import Loader from '../../../base/components/Loader';
import { Box, Button, Checkbox, IconButton, TextField, Typography } from '@mui/material';
import { TariffsModal } from './components/TariffsModal';
import { Close } from '@mui/icons-material';

export const UserPage = observer(() => {
  const { id } = useParams();
  const userStore = useLocalObservable(() => new UserStore());

  const [tariffsVisible, setTariffsVisible] = useState(false);

  useEffect(() => {
    userStore.getUser(id);
  }, []);

  const handleOpenTariffs = () => setTariffsVisible(true);

  const handleCloseTariffs = () => setTariffsVisible(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const lastName = data.get('lastName')?.toString();
    const name = data.get('name')?.toString();
    const patronymic = data.get('patronymic')?.toString();
    const phone = data.get('phone')?.toString();
    const verified = data.get('verified')?.toString() === 'on';

    userStore.handleSubmit({
      lastName,
      name,
      patronymic,
      phone,
      verified,
    });
  };

  if (!userStore.user || userStore.loading) {
    return <Loader />;
  }

  const user = userStore.user;

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Field label={'Id'} value={user.id} />
      <Box component={'form'} onSubmit={handleSubmit}>
        <TextField
          id="lastName"
          label="Фамилия"
          name="lastName"
          margin="normal"
          defaultValue={user.lastName}
          required
          fullWidth
        />
        <TextField id="name" label="Имя" name="name" margin="normal" defaultValue={user.name} required fullWidth />
        <TextField
          id="patronymic"
          label="Отчество"
          name="patronymic"
          margin="normal"
          defaultValue={user.patronymic}
          fullWidth
        />
        <TextField id="phone" label="Телефон" name="phone" margin="normal" defaultValue={user.phone} fullWidth />
        <Box flexDirection={'row'}>
          <Typography display={'inline'}>Подтвержден</Typography>
          <Checkbox id={'verified'} name={'verified'} defaultChecked={!!user.verified} />
        </Box>

        <Typography fontWeight={'bold'}>Тарифы</Typography>
        {user.userTariffs.map((uTariff, index) => {
          if (userStore.deleteUserTariffIds.has(uTariff.id ?? '')) {
            return null;
          }

          return (
            <Box
              key={`utariff_${uTariff.id ?? index}`}
              mb={1}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ display: 'flex' }}
            >
              <IconButton color={'error'} onClick={() => userStore.addDeleteUserTariffId(uTariff.id ?? '')}>
                <Close />
              </IconButton>
              <Box>
                <Typography>{`${uTariff.tariff?.name} ${uTariff.tariff?.price}`}</Typography>
                <Typography>{`Начало ${uTariff.startDate}`}</Typography>
              </Box>
            </Box>
          );
        })}

        <Typography>Добавленные:</Typography>
        {userStore.addTariffs.map((tariff, index) => {
          return (
            <Box
              key={`tariff_${tariff.id ?? index}`}
              mb={1}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ display: 'flex' }}
            >
              <IconButton color={'error'} onClick={() => userStore.handleDeleteAddedTariff(tariff)}>
                <Close />
              </IconButton>
              <Box>
                <Typography>{`${tariff?.name} ${tariff?.price}`}</Typography>
              </Box>
            </Box>
          );
        })}

        <Button variant="contained" onClick={handleOpenTariffs}>
          Добавить тариф
        </Button>

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Сохранить
        </Button>
      </Box>
      <TariffsModal
        isVisible={tariffsVisible}
        handleCloseModal={handleCloseTariffs}
        handleSubmit={userStore.handleAddUserTariff}
      />
    </div>
  );
});
