import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import TextField from '@mui/material/TextField';
import { UsersStore } from '../../stores/UsersStore';

interface IFilterModalProps {
  isVisible: boolean;
  handleCloseModal: () => void;
  usersStore: UsersStore;
  handleSubmit: () => void;
}

export const FilterModal = (props: IFilterModalProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const id = data.get('id')?.toString() ?? '';
    const lastName = data.get('lastName')?.toString() ?? '';
    const name = data.get('name')?.toString() ?? '';
    const patronymic = data.get('patronymic')?.toString() ?? '';
    const phone = data.get('phone')?.toString() ?? '';

    props.usersStore.changeFilterForm({
      id,
      lastName,
      name,
      patronymic,
      phone,
    });
    props.handleCloseModal();
    props.handleSubmit();
  };

  return (
    <Modal
      open={props.isVisible}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" noValidate onSubmit={handleSubmit} sx={style}>
        <TextField
          defaultValue={props.usersStore.filterForm.id}
          margin="normal"
          fullWidth
          id="id"
          label="Id"
          name="id"
        />
        <TextField
          defaultValue={props.usersStore.filterForm.lastName}
          margin="normal"
          fullWidth
          id="lastName"
          label="Фамилия"
          name="lastName"
        />
        <TextField
          defaultValue={props.usersStore.filterForm.name}
          margin="normal"
          fullWidth
          id="name"
          label="Имя"
          name="name"
        />
        <TextField
          defaultValue={props.usersStore.filterForm.patronymic}
          margin="normal"
          fullWidth
          id="patronymic"
          label="Отчество"
          name="patronymic"
        />
        <TextField
          defaultValue={props.usersStore.filterForm.phone}
          margin="normal"
          fullWidth
          id="phone"
          label="Телефон"
          name="phone"
        />

        <Button type="submit" fullWidth variant={'contained'} sx={{ mt: 2, mb: 2 }}>
          Применить
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
