import { UsersApiRepository } from './repositories/UsersApiRepository';
import { UserFactory } from './UserFactory';
import { SearchUsersDto } from './dto/SearchUsersDto';
import { UpdateUserDto } from './dto/UpdateUserDto';

export class UsersService {
  private apiRepository: UsersApiRepository;
  private factory: UserFactory;

  constructor() {
    this.apiRepository = new UsersApiRepository();
    this.factory = new UserFactory();
  }

  getUsers = async (dto: SearchUsersDto) => {
    const { data } = await this.apiRepository.getUsers(dto);
    const users = this.factory.createUsers(data.data);

    return {
      users,
      total: data.total,
    };
  };

  getUser = async (id: string) => {
    const { data } = await this.apiRepository.getUser(id);

    return this.factory.createUser(data);
  };

  updateUser = async (id: string, dto: UpdateUserDto) => {
    const { data } = await this.apiRepository.updateUser(id, dto);

    return this.factory.createUser(data);
  };
}
