import AbstractFactory from '../../base/AbstractFactory';
import { ApiLog } from './models/ApiLog';

export class ApiLogsFactory extends AbstractFactory {
  createApiLog = (json: any) => {
    return this.create<ApiLog>(ApiLog, json);
  };

  createApiLogs = (json: any[]) => {
    return json?.map((j) => this.createApiLog(j)) ?? [];
  };
}
