import { makeAutoObservable, runInAction } from 'mobx';
import { MapFile } from '../../../types/MapFile';
import DocumentsService from '../../documents/DocumentsService';
import DocumentHelper from '../../documents/helpers/DocumentsHelper';
import { DocumentType, EntityType } from '../../documents/models/Document';
import Question from '../model/Question';
import QuestionService from '../QuestionService';
import { CreateUpdateQuestionForm } from '../types/CreateUpdateQuestionForm';

export default class QuestionCreateUpdateStore {
  isLoading = true;
  question: Question | null = null;

  ticketId: string | null = null;
  lessonTestId: string | null = null;
  questionId: string | null = null;

  files: Map<string, MapFile> = new Map();
  deleteFileIds: string[] = [];

  answers: string[] = [];

  private onSuccess?: () => void;

  private questionService: QuestionService;
  private documentsService: DocumentsService;

  constructor() {
    this.questionService = new QuestionService();
    this.documentsService = new DocumentsService();
    makeAutoObservable(this);
  }

  init = ({
    lessonTestId,
    ticketId,
    questionId,
  }: {
    lessonTestId?: string | null;
    ticketId?: string | null;
    questionId?: string | null;
  }) => {
    this.ticketId = ticketId ?? null;
    this.lessonTestId = lessonTestId ?? null;
    this.questionId = questionId ?? null;
    this.getQuestion();
  };

  getQuestion = () => {
    if (!this.questionId) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    return this.questionService
      .get(this.questionId)
      .then(question => {
        runInAction(() => {
          this.question = question;
          this.answers = this.question.answers;
          question.documents.forEach(d => {
            if (d.documentType && d.id) {
              this.files.set(d.documentType, {
                id: d.id,
                documentType: d.documentType,
                url: DocumentHelper.urlStr(d.id),
              });
            }
          });
        });
      })
      .catch(() => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  submit = (form: CreateUpdateQuestionForm, onSuccess: () => void) => {
    this.onSuccess = onSuccess;
    if (this.questionId) {
      return this.update(form);
    }
    this.create(form);
  };

  create = (form: CreateUpdateQuestionForm) => {
    this.isLoading = true;
    return this.questionService
      .create({
        questionText: form.questionText,
        comment: form.comment,
        answers: this.answers,
        answerValidIndex: form.answerValidIndex,
        ticketId: this.ticketId ?? undefined,
        lessonTestId: this.lessonTestId ?? undefined,
      })
      .then(question => {
        this.questionId = question.id;
        this.uploadDeleteFiles();
      })
      .catch(() => {
        runInAction(() => {
          this.isLoading = false;
        });
        //@TODO alert error
      });
  };

  update = (form: CreateUpdateQuestionForm) => {
    if (!this.questionId) {
      return;
    }
    this.isLoading = true;
    return this.questionService
      .update(this.questionId, {
        questionText: form.questionText,
        comment: form.comment,
        answers: this.answers,
        answerValidIndex: form.answerValidIndex,
        sort: this.question?.sort ?? undefined,
        ticketId: this.ticketId ?? undefined,
        lessonTestId: this.lessonTestId ?? undefined,
      })
      .then(() => {
        this.uploadDeleteFiles();
      })
      .catch(() => {
        runInAction(() => {
          this.isLoading = false;
        });
        //@TODO alert error
      });
  };

  uploadDeleteFiles = () => {
    if (this.files.size === 0 && this.deleteFileIds.length === 0) {
      return this.onSuccess && this.onSuccess();
    }
    return Promise.all(this.deleteFileIds.map(id => this.documentsService.deleteDocument(id)))
      .then(() => {
        const newFiles = Array.from(this.files.values()).filter(f => !f.id);
        return Promise.all(newFiles.map(f => this.uploadFile(f)))
          .then(() => {
            return this.onSuccess && this.onSuccess();
          })
          .catch(() => {
            //@TODO alert error
          });
      })
      .catch(() => {
        //@TODO alert error
      });
  };

  uploadFile = (mapFile: MapFile): Promise<any> => {
    if (!this.questionId || !mapFile.file) {
      return Promise.resolve();
    }
    return this.documentsService.uploadDocument(
      {
        entityId: this.questionId,
        entityType: EntityType.Question,
        documentType: mapFile.documentType,
      },
      mapFile.file,
    );
  };

  onSelectFile = (documentType: DocumentType, file: File) => {
    if (this.files.get(documentType)?.id) {
      this.deleteFileIds.push(this.files.get(documentType)!.id!);
    }
    this.files.set(documentType, {
      url: URL.createObjectURL(file),
      file,
      documentType,
    });
  };

  deleteFile = (documentType: DocumentType) => {
    const file = this.files.get(documentType);
    if (file?.id) {
      this.deleteFileIds.push(file!.id!);
    }
    this.files.delete(documentType);
  };

  addAnswer = (answer: string) => {
    this.answers.push(answer);
  };

  updateAnswer = (index: number, answer: string) => {
    this.answers[index] = answer;
  };

  deleteAnswer = (index: number) => {
    this.answers.splice(index, 1);
  };
}
