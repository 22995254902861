export const LIMIT = 20;

export const TableColumns = [
  {
    Header: 'Status',
    accessor: 'statusCode',
  },
  {
    Header: 'Method',
    accessor: 'method',
  },
  {
    Header: 'Url',
    accessor: 'originalUrl',
  },
  {
    Header: 'UserId',
    accessor: 'userId',
  },
  {
    Header: 'appPlatform',
    accessor: 'appPlatform',
  },
  {
    Header: 'appVersion',
    accessor: 'appVersion',
  },
  {
    Header: 'CreationDate',
    accessor: 'creationDate',
  },
];
