import React from 'react';

interface IFieldProps {
  label: string;
  value?: string | number | null;
}

export const Field = (props: IFieldProps) => {
  return (
    <>
      <p
        style={{
          fontWeight: 'bold',
        }}
      >
        {props.label}
      </p>
      <p>{props.value}</p>
    </>
  );
};
