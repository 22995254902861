import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import LessonCreateEditStore from '../stores/LessonCreateEditStore';
import ReactPlayer from 'react-player';
import { Box, Button } from '@mui/material';
import DocumentHelper from '../../documents/helpers/DocumentsHelper';
import FilePickerModal from '../../../base/components/FilePickerModal';
import { DocumentType } from '../../documents/models/Document';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';
import { isEmpty } from '../../../helpers/CommonHelper';

type LessonCreateUpdateFilesProps = {
  store: LessonCreateEditStore;
};

type ConfirmDeleteModalState = {
  isVisible: boolean;
  documentType: DocumentType | null;
  index: number | null;
};

const InitConfirmDeleteModalState: ConfirmDeleteModalState = {
  isVisible: false,
  documentType: null,
  index: null,
};

const LessonCreateUpdateFiles = observer(({ store }: LessonCreateUpdateFilesProps) => {
  const { files, onSelectFile, deleteFile } = store;

  const [filePickerProps, setFilePickerProps] = useState<{
    isVisible: boolean;
    documentType?: DocumentType;
    documentName?: string;
    isWithDocumentName: boolean;
  }>({
    isVisible: false,
    isWithDocumentName: false,
  });

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>(InitConfirmDeleteModalState);

  const open = (documentType: DocumentType) =>
    setFilePickerProps({
      isVisible: true,
      documentType,
      isWithDocumentName: documentType === DocumentType.PdfDocument,
    });
  const close = () =>
    setFilePickerProps({
      isVisible: false,
      isWithDocumentName: false,
    });

  const openConfirmDeleteModal = (documentType: DocumentType, index: number) =>
    setConfirmDeleteModalState({
      isVisible: true,
      documentType,
      index,
    });
  const closeConfirmDeleteModal = () => setConfirmDeleteModalState(InitConfirmDeleteModalState);
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (confirmDeleteModalState.documentType && !isEmpty(confirmDeleteModalState.index)) {
      deleteFile(confirmDeleteModalState.documentType, confirmDeleteModalState.index!);
    }
  };

  const video = (files.get(DocumentType.Video) ?? [])[0];
  const pdfFiles = files.get(DocumentType.PdfDocument) ?? [];
  return (
    <>
      <h3>Файлы</h3>
      <Box style={{ paddingLeft: '16px' }}>
        <h4>Видео</h4>
        {!!video && <ReactPlayer url={video.url} width="30%" height="30%" controls={true} />}
        <Box sx={{ flexDirection: 'row' }}>
          {!video && (
            <Button variant="contained" onClick={() => open(DocumentType.Video)}>
              Загрузить
            </Button>
          )}
          {!!video && (
            <Button variant="contained" color="error" onClick={() => openConfirmDeleteModal(DocumentType.Video, 0)}>
              Удалить
            </Button>
          )}
        </Box>
      </Box>
      <h4>PDF документы</h4>
      <Button variant="contained" onClick={() => open(DocumentType.PdfDocument)}>
        Добавить
      </Button>
      <Box style={{ paddingLeft: '16px' }}>
        {pdfFiles.map((file, index) => {
          return (
            <Box key={file.url}>
              <h4>{file.documentName}</h4>
              <Box sx={{ flexDirection: 'row' }}>
                <Button variant="contained" onClick={() => window.open(file.url)}>
                  Открыть
                </Button>
                <Button
                  sx={{ ml: '16px' }}
                  variant="contained"
                  color="error"
                  onClick={() => openConfirmDeleteModal(DocumentType.PdfDocument, index)}
                >
                  Удалить
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>

      <FilePickerModal
        isVisible={filePickerProps.isVisible}
        close={close}
        accept={DocumentHelper.mapDocumentTypeFilePickerAccept[filePickerProps.documentType ?? '']}
        isWithDocumentName={filePickerProps.isWithDocumentName}
        documentName={filePickerProps.documentName}
        onSelect={(file, docName) => {
          filePickerProps.documentType && onSelectFile(filePickerProps.documentType, file, docName);
        }}
      />

      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
});

export default LessonCreateUpdateFiles;
