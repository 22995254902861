import AbstractModel from '../../../base/AbstractModel';
import Document from '../../documents/models/Document';

export default class Question extends AbstractModel {
  id: string | null = null;
  questionText: string | null = null;
  comment: string | null = null;
  answers: string[] = [];
  answerValidIndex: number | null = null;
  documents: Document[] = [];
  sort: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
