import { CreateLectureDto } from './dto/CreateLectureDto';
import { LecturesFactory } from './LecturesFactory';
import { Lecture } from './models/Lecture';
import LecturesApiRepository from './repositories/LecturesApiRepository';

class LecturesService {
  private lecturesFactory: LecturesFactory;
  private lecturesApi: LecturesApiRepository;

  constructor() {
    this.lecturesFactory = new LecturesFactory();
    this.lecturesApi = new LecturesApiRepository();
  }

  getAll = () => {
    return this.lecturesApi.getAll().then(response => {
      const lectures = this.lecturesFactory.createLectures(response.data as any[]);

      return this.sortLectures(lectures);
    });
  };

  getById = (id: string) => {
    return this.lecturesApi.getById(id).then(response => {
      return this.lecturesFactory.createLecture(response.data);
    });
  };

  create = (dto: CreateLectureDto) => {
    return this.lecturesApi.create(dto).then(response => {
      return this.lecturesFactory.createLecture(response.data);
    });
  };

  update = (id: string, dto: CreateLectureDto) => {
    return this.lecturesApi.update(id, dto).then(response => {
      return this.lecturesFactory.createLecture(response.data);
    });
  };

  delete = (id: string) => {
    return this.lecturesApi.delete(id);
  };

  sortLectures = (lectures: Lecture[]) => {
    return lectures.sort((a, b) => (a.num ?? 0) - (b.num ?? 0));
  };
}

export default LecturesService;
