import AbstractModel from '../../../base/AbstractModel';
import Document from '../../documents/models/Document';

export default class Lesson extends AbstractModel {
  id: string | null = null;
  name: string | null = null;
  textPdd: string | null = null;
  textFz: string | null = null;
  textKoap: string | null = null;
  sort: number | null = null;
  //   lecture: Lecture;
  // lectureId: string;
  //   lessonTest: LessonTest;
  lessonTestId: string | null = null;
  documents: Document[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
