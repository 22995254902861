import AbstractFactory from '../../base/AbstractFactory';
import { User } from './models/User';
import { UserTariffsFactory } from '../user-tariffs/UserTariffsFactory';

export class UserFactory extends AbstractFactory {
  private userTariffFactory: UserTariffsFactory;

  constructor() {
    super();
    this.userTariffFactory = new UserTariffsFactory();
  }

  createUser = (json: any) => {
    const user = this.create<User>(User, json);
    user.userTariffs = this.userTariffFactory.createUserTariffs(user.userTariffs);

    return user;
  };

  createUsers = (json: any[]) => {
    return json?.map((j) => this.createUser(j)) ?? [];
  };
}
