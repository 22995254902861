import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../base/components/Loader';
import { useQuery } from '../../../base/hooks/useQuery';
import CreateUpdateAnswerModal from '../components/CreateUpdateAnswerModal';
import QuestionCreateUpdateFiles from '../components/QuestionCreateUpdateFiles';
import QuestionCreateUpdateStore from '../stores/QuestionCreateUpdateStore';
import { CreateUpdateQuestionForm } from '../types/CreateUpdateQuestionForm';
import { isEmpty } from '../../../helpers/CommonHelper';
import { ConfirmDeleteModal } from '../../../base/components/ConfirmDeleteModal';

type ConfirmDeleteModalState = {
  isVisible: boolean;
  index: number | null;
};

const InitConfirmDeleteModalState: ConfirmDeleteModalState = {
  isVisible: false,
  index: null,
};

const QuestionCreateUpdate = observer(() => {
  const navigate = useNavigate();
  const query = useQuery();
  const lessonTestId = query.get('lessonTestId');
  const ticketId = query.get('ticketId');
  const { questionId } = useParams();

  const store = useLocalObservable(() => new QuestionCreateUpdateStore());
  const { isLoading, question, answers } = store;
  const { init, addAnswer, updateAnswer, deleteAnswer } = store;
  const [answerModalProps, setAnswerModalProps] = useState<{
    isVisible: boolean;
    answer: string | null;
    index: number | null;
  }>({
    isVisible: false,
    answer: null,
    index: null,
  });

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>(InitConfirmDeleteModalState);

  useEffect(() => {
    init({ lessonTestId, ticketId, questionId });
  }, [init, lessonTestId, ticketId, questionId]);

  const openConfirmDeleteModal = (index: number) =>
    setConfirmDeleteModalState({
      isVisible: true,
      index,
    });
  const closeConfirmDeleteModal = () => setConfirmDeleteModalState(InitConfirmDeleteModalState);
  const onConfirmDelete = () => {
    closeConfirmDeleteModal();

    if (!isEmpty(confirmDeleteModalState.index)) {
      deleteAnswer(confirmDeleteModalState.index!);
    }
  };

  const openAnswerModal = ({ index, answer }: { index?: number; answer?: string }) => {
    setAnswerModalProps({
      isVisible: true,
      answer: answer ?? null,
      index: index ?? null,
    });
  };

  const closeAnswerModal = () => {
    setAnswerModalProps({
      isVisible: false,
      answer: null,
      index: null,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(Array.from(data.entries()));
    const questionText = data.get('questionText')?.toString();
    const answerValidIndex = data.get('answerValidIndex')?.toString();
    if (!answerValidIndex || !questionText) {
      return;
    }
    const form: CreateUpdateQuestionForm = {
      questionText: questionText,
      comment: data.get('comment')?.toString(),
      answerValidIndex: parseInt(answerValidIndex),
      ticketId: ticketId ?? undefined,
      lessonTestId: lessonTestId ?? undefined,
    };
    store.submit(form, () => navigate(-1));
  };

  return (
    <div
      style={{
        padding: 16,
      }}
    >
      <Box component={'form'} onSubmit={handleSubmit}>
        <TextField
          id="questionText"
          label="Вопрос"
          name="questionText"
          margin="normal"
          defaultValue={question?.questionText}
          multiline={true}
          maxRows={50}
          required
          fullWidth
          autoFocus
        />
        <TextField
          id="comment"
          label="Комментарий"
          name="comment"
          margin="normal"
          defaultValue={question?.comment}
          multiline
          maxRows={50}
          fullWidth
        />
        <Divider />
        <Box>
          <FormControl style={{ marginTop: 16, marginBottom: 16 }}>
            <h3>Ответы</h3>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={question?.answerValidIndex ?? 0}
              name="answerValidIndex"
            >
              {answers.map((answer, index) => {
                return (
                  <Box key={index.toString()}>
                    <FormControlLabel value={index} control={<Radio />} label={answer} />

                    <Box style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <IconButton
                        sx={{ ml: '16px' }}
                        onClick={() => {
                          openAnswerModal({ index, answer });
                        }}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        sx={{ ml: '16px' }}
                        onClick={() => {
                          openConfirmDeleteModal(index);
                        }}
                        aria-label="delete"
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => openAnswerModal({})}>
          Добавить
        </Button>
        <Divider />
        <QuestionCreateUpdateFiles store={store} />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {questionId ? 'Сохранить' : 'Создать'}
        </Button>
      </Box>

      <CreateUpdateAnswerModal
        {...answerModalProps}
        close={closeAnswerModal}
        onCreate={addAnswer}
        onUpdate={updateAnswer}
      />

      <ConfirmDeleteModal
        isVisible={confirmDeleteModalState.isVisible}
        close={closeConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
});

export default QuestionCreateUpdate;
