import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { isEmpty } from '../../../helpers/CommonHelper';

interface IPaginationProps {
  limit: number;
  total: number;

  page: number;

  handlePageClick: (page: number) => void;
}

export const Pagination = (props: IPaginationProps) => {
  const pageCount = Math.ceil(props.total / props.limit);

  const handleClick = (clickEvent: {
    index: number | null;
    selected: number;
    nextSelectedPage: number | undefined;
    event: object;
    isPrevious: boolean;
    isNext: boolean;
    isBreak: boolean;
    isActive: boolean;
  }) => {
    if (!isEmpty(clickEvent.nextSelectedPage)) {
      props.handlePageClick(clickEvent.nextSelectedPage!);
    }
  };

  return (
    <MyPaginate
      breakLabel="..."
      nextLabel="next >"
      initialPage={props.page}
      onClick={handleClick}
      pageRangeDisplayed={props.limit}
      pageCount={pageCount}
      previousLabel="< previous"
      //@ts-ignore
      renderOnZeroPageCount={null}
    />
  );
};

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
