import { makeAutoObservable, runInAction } from 'mobx';
import { User } from '../models/User';
import { UsersService } from '../UsersService';
import { UpdateUserDto } from '../dto/UpdateUserDto';
import { UserTariffService } from '../../user-tariffs/UserTariffService';
import { Tariff } from '../../tariffs/models/Tariff';

export class UserStore {
  loading = false;
  user: User | null = null;

  deleteUserTariffIds = new Set<string>();
  addTariffs: Tariff[] = [];

  private usersService: UsersService;
  private userTariffService: UserTariffService;

  constructor() {
    makeAutoObservable(this);
    this.usersService = new UsersService();
    this.userTariffService = new UserTariffService();
  }

  getUser = (id?: string) => {
    if (!id) {
      return;
    }

    this.setLoading(true);

    return this.usersService
      .getUser(id)
      .then((user) => {
        runInAction(() => {
          this.user = user;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateUser = (form: UpdateUserDto) => {
    if (!this.user?.id) {
      return;
    }

    this.setLoading(true);

    return this.usersService
      .updateUser(this.user.id, form)
      .then((user) => {
        runInAction(() => {
          this.user = user;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  addDeleteUserTariffId = (id: string) => {
    this.deleteUserTariffIds.add(id);
  };

  handleAddUserTariff = (tariff: Tariff) => {
    this.addTariffs.push(tariff);
  };

  handleDeleteAddedTariff = (tariff: Tariff) => {
    this.addTariffs = this.addTariffs.filter((t) => t.id !== tariff.id);
  };

  deleteUserTariffs = () => {
    this.setLoading(true);

    return Promise.all(Array.from(this.deleteUserTariffIds.values()).map((id) => this.userTariffService.delete(id)))
      .then(() => {
        runInAction(() => {
          this.deleteUserTariffIds = new Set();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  addUserTariffs = () => {
    if (!this.user?.id) {
      return Promise.resolve();
    }

    this.setLoading(true);

    return Promise.all(
      this.addTariffs.map((t) =>
        this.userTariffService.create({
          userId: this.user!.id!,
          tariffId: t.id ?? '',
        }),
      ),
    )
      .then(() => {
        runInAction(() => {
          this.addTariffs = [];
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  handleSubmit = async (form: UpdateUserDto) => {
    await this.deleteUserTariffs();
    await this.addUserTariffs();
    await this.updateUser(form);
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
