import { ApiLogsApiRepository } from './repositories/ApiLogsApiRepository';
import { SearchLogsDto } from './dto/SearchLogsDto';
import { ApiLogsFactory } from './ApiLogsFactory';

export class ApiLogsService {
  private apiRepository: ApiLogsApiRepository;
  private factory: ApiLogsFactory;

  constructor() {
    this.apiRepository = new ApiLogsApiRepository();
    this.factory = new ApiLogsFactory();
  }

  getLogs = async (dto: SearchLogsDto) => {
    const { data } = await this.apiRepository.getLogs(dto);
    const logs = this.factory.createApiLogs(data.data);

    return {
      logs,
      total: data.total ?? 0,
    };
  };

  getLog = async (id: string) => {
    const { data } = await this.apiRepository.getLog(id);

    return this.factory.createApiLog(data);
  };
}
