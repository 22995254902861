import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import MenuBook from '@mui/icons-material/MenuBook';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import DataObject from '@mui/icons-material/DataObject';
import Payment from '@mui/icons-material/Payment';
import People from '@mui/icons-material/People';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../hooks/useRootStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const drawerWidth = 240;

enum NavigationPath {
  Tickets = '/tickets',
  Lectures = '/lectures',
  Lessons = '/lessons',
  Questions = '/questions',
  ApiLogs = '/api-logs',
  Tariffs = '/tariffs',
  Users = '/users',
}

const items = [
  {
    title: 'Билеты',
    icon: ReceiptLong,
    path: NavigationPath.Tickets,
  },
  {
    title: 'Лекции',
    icon: MenuBook,
    path: NavigationPath.Lectures,
  },
  {
    title: 'Тарифы',
    icon: Payment,
    path: NavigationPath.Tariffs,
  },
  {
    title: 'Пользователи',
    icon: People,
    path: NavigationPath.Users,
  },
  {
    title: 'Api logger',
    icon: DataObject,
    path: NavigationPath.ApiLogs,
  },
];

const pathToTitleMap: { [key: string]: string } = {
  [NavigationPath.Tickets]: 'Билеты',
  [NavigationPath.Lectures]: 'Лекции',
  [NavigationPath.ApiLogs]: 'Api logs',
  [NavigationPath.Lessons]: 'Занятия',
  [NavigationPath.Questions]: 'Вопросы',
  [NavigationPath.Tariffs]: 'Тарифы',
  [NavigationPath.Users]: 'Пользователи',
};

const NavigationBar = observer(() => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const title = useMemo(() => {
    let title = pathToTitleMap[path] ?? '';
    if (title === '' && path.includes(NavigationPath.Lessons)) {
      if (path.includes('/edit')) {
        title = 'Редактирование занятия';
      }
      if (path.includes('/create')) {
        title = 'Создание занятия';
      }
    }
    if (title === '' && path.includes(NavigationPath.Questions)) {
      if (path.includes('/edit')) {
        title = 'Редактирование вопроса';
      }
      if (path.includes('/create')) {
        title = 'Создание вопроса';
      }
    }
    if (title === '' && path.includes(NavigationPath.ApiLogs)) {
      title = 'Api Log';
    }
    if (title === '' && path.includes(NavigationPath.Users)) {
      title = 'Пользователь';
    }

    return title;
  }, [path]);

  if (!authStore.isAuthorized) {
    return null;
  }

  return (
    <div>
      <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Button color="error" onClick={() => authStore.logout()}>
            Выйти
          </Button>
        </Toolbar>
        <Divider />
        <List>
          {items.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <ListItemIcon>{<item.icon />}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
});

export default NavigationBar;
