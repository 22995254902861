import AbstractModel from '../../../base/AbstractModel';
import { Tariff } from '../../tariffs/models/Tariff';

export class UserTariff extends AbstractModel {
  id: string | null = null;
  startDate: string | null = null;
  tariffId: string | null = null;
  tariff: Tariff | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
