import AbstractFactory from '../../base/AbstractFactory';
import DocumentFactory from '../documents/DocumentFactory';
import Lesson from './models/Lesson';

export default class LessonsFactory extends AbstractFactory {
  private documentFactory: DocumentFactory;

  constructor() {
    super();
    this.documentFactory = new DocumentFactory();
  }

  createLesson = (data: any) => {
    const res = this.create<Lesson>(Lesson, data);
    res.documents = this.documentFactory.createDocuments(data?.documents ?? []);

    return res;
  };

  createLessons = (data: any[]) => {
    return data.map(d => this.createLesson(d));
  };
}
