import AbstractFactory from '../../base/AbstractFactory';
import LessonsFactory from '../lessons/LessonsFactory';
import { Lecture } from './models/Lecture';

export class LecturesFactory extends AbstractFactory {
  private lessonsFactory: LessonsFactory;

  constructor() {
    super();
    this.lessonsFactory = new LessonsFactory();
  }

  createLecture = (data: any) => {
    let res = this.create<Lecture>(Lecture, data);
    res.lessons = data.lessons ? this.lessonsFactory.createLessons(data.lessons) : [];

    return res;
  };

  createLectures = (data: any[]) => {
    return data.map(d => this.createLecture(d));
  };
}
