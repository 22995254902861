import AbstractFactory from '../../base/AbstractFactory';
import { Tariff } from './models/Tariff';

export class TariffFactory extends AbstractFactory {
  createTariff = (json: any) => {
    const tariff = this.create<Tariff>(Tariff, json);
    tariff.price = tariff.price ? tariff.price / 100 : null;
    tariff.duration = tariff.duration ? tariff.duration / 24 / 60 / 60 : null;

    return tariff;
  };

  createTariffs = (json: any[]) => {
    return json?.map((j) => this.createTariff(j)) ?? [];
  };
}
