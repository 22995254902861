import { TariffApiRepository } from './repositories/TariffApiRepository';
import { TariffFactory } from './TariffFactory';
import { CreateTariffDto } from './dto/CreateTariffDto';
import { UpdateTariffDto } from './dto/UpdateTariffDto';

export class TariffsService {
  private apiRepository: TariffApiRepository;
  private factory: TariffFactory;

  constructor() {
    this.apiRepository = new TariffApiRepository();
    this.factory = new TariffFactory();
  }

  getTariffs = async () => {
    const { data } = await this.apiRepository.getTariffs();

    return this.factory.createTariffs(data);
  };

  getTariff = async (id: string) => {
    const { data } = await this.apiRepository.getTariff(id);

    return this.factory.createTariff(data);
  };

  createTariff = async (dto: CreateTariffDto) => {
    // days to seconds
    dto.duration = dto.duration * 24 * 60 * 60;
    dto.price = dto.price * 100;
    const { data } = await this.apiRepository.createTariff(dto);

    return this.factory.createTariff(data);
  };

  updateTariff = async (id: string, dto: UpdateTariffDto) => {
    if (dto.duration) {
      // days to seconds
      dto.duration = dto.duration * 24 * 60 * 60;
    }
    if (dto.price) {
      dto.price = dto.price * 100;
    }

    const { data } = await this.apiRepository.updateTariff(id, dto);

    return this.factory.createTariff(data);
  };
}
