import { makeAutoObservable, runInAction } from 'mobx';
import { ApiLog } from '../models/ApiLog';
import { ApiLogsService } from '../ApiLogsService';

export class ApiLogStore {
  loading = false;
  apiLog: ApiLog | null = null;

  private apiLogsService: ApiLogsService;

  constructor() {
    makeAutoObservable(this);
    this.apiLogsService = new ApiLogsService();
  }

  getLog = (id: string) => {
    this.setLoading(true);

    return this.apiLogsService
      .getLog(id)
      .then((log) => {
        runInAction(() => {
          this.apiLog = log;
        });
      })
      .catch(() => {
        // TODO alert error
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
