import { CreateUpdateLessonDto } from './dto/CreateUpdateLessonDto';
import LessonsFactory from './LessonsFactory';
import Lesson from './models/Lesson';
import LessonsApiRepository from './repositories/LessonsApiRepository';

export default class LessonsService {
  private lessonsFactory: LessonsFactory;
  private lessonsApi: LessonsApiRepository;

  constructor() {
    this.lessonsFactory = new LessonsFactory();
    this.lessonsApi = new LessonsApiRepository();
  }

  getLessons = (lectureId: string) => {
    return this.lessonsApi.getByLectureId(lectureId).then(response => {
      const lessons = this.lessonsFactory.createLessons(response.data as any[]);

      return this.sort(lessons);
    });
  };

  getLesson = (id: string) => {
    return this.lessonsApi.getById(id).then(response => {
      return this.lessonsFactory.createLesson(response.data);
    });
  };

  create = (dto: CreateUpdateLessonDto) => {
    return this.lessonsApi.create(dto).then(response => {
      return this.lessonsFactory.createLesson(response.data);
    });
  };

  update = (id: string, dto: CreateUpdateLessonDto) => {
    return this.lessonsApi.update(id, dto).then(response => {
      return this.lessonsFactory.createLesson(response.data);
    });
  };

  delete = (id: string) => {
    return this.lessonsApi.delete(id);
  };

  sort = (lessons: Lesson[]) => {
    return lessons.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
  };
}
