import { Box, Button, Modal, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';

type INumberModalProps = {
  isVisible: boolean;
  close: () => void;
  initialNum?: number | null;
  onSubmit: (num: number) => void;
};

const NumberModal = observer(({ initialNum, isVisible, close, onSubmit }: INumberModalProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const num = parseInt(data.get('numLecture')?.toString() ?? '');
    if (!Number.isInteger(num)) {
      return;
    }

    onSubmit(num);
    close();
  };

  return (
    <Modal
      open={isVisible}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component={'form'} onSubmit={handleSubmit} sx={style}>
        <TextField
          margin="normal"
          defaultValue={initialNum}
          required
          fullWidth
          id="num"
          label="Номер"
          name="numLecture"
          autoFocus
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {typeof initialNum === 'number' ? 'Сохранить' : 'Создать'}
        </Button>
      </Box>
    </Modal>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default NumberModal;
