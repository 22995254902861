import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../base/components/Loader';
import { useRootStore } from '../../../base/hooks/useRootStore';

const InitPage = observer(() => {
  const { authStore } = useRootStore();
  const { isAuthorized } = authStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/lectures');
      return;
    }

    navigate('/login');
  }, [isAuthorized]);

  return <Loader />;
});

export default InitPage;
