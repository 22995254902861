import { makeAutoObservable, runInAction } from 'mobx';
import { Tariff } from '../models/Tariff';
import { TariffsService } from '../TariffsService';
import { UpdateTariffDto } from '../dto/UpdateTariffDto';
import { CreateTariffDto } from '../dto/CreateTariffDto';

export class TariffsStore {
  loading = false;
  tariffs: Tariff[] = [];

  private tariffsService: TariffsService;

  constructor() {
    makeAutoObservable(this);
    this.tariffsService = new TariffsService();
  }

  getTariffs = () => {
    this.setLoading(true);

    return this.tariffsService
      .getTariffs()
      .then((tariffs) => {
        runInAction(() => {
          this.tariffs = tariffs;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  createTariff = (dto: CreateTariffDto) => {
    this.setLoading(true);

    return this.tariffsService
      .createTariff(dto)
      .then(() => {
        this.getTariffs();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateTariff = (dto: UpdateTariffDto) => {
    return this.tariffsService
      .updateTariff(dto.id, dto)
      .then((updated) => {
        runInAction(() => {
          const index = this.tariffs.findIndex((t) => t.id === dto.id);

          if (index > -1) {
            this.tariffs[index] = updated;
          }
        });
      })
      .catch(() => {});
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
