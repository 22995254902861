import { Box, CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );
};

export const LoaderAbsolute = () => {
  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Loader />
    </Box>
  );
};

export default Loader;
