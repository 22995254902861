import AbstractModel from '../../../base/AbstractModel';
import Lesson from '../../lessons/models/Lesson';

export class Lecture extends AbstractModel {
  id: string | null = null;
  num: number | null = null;
  lessons: Lesson[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
