import { makeAutoObservable, runInAction } from 'mobx';
import { User } from '../models/User';
import { UsersService } from '../UsersService';
import { IUsersFilterForm, UsersFilterForm } from '../forms/UsersFilterForm';
import { LIMIT } from '../consts/UsersConsts';

export class UsersStore {
  loading = false;
  users: User[] = [];
  total = 0;

  filterForm = UsersFilterForm;

  private usersService: UsersService;

  constructor() {
    makeAutoObservable(this);
    this.usersService = new UsersService();
  }

  changeFilterForm = (form: IUsersFilterForm) => {
    this.filterForm = {
      ...this.filterForm,
      ...form,
    };
  };

  getUsers = (page = 0) => {
    this.setLoading(false);

    return this.usersService
      .getUsers({
        ...this.filterForm,
        skip: page * LIMIT,
        limit: LIMIT,
      })
      .then(({ users, total }) => {
        runInAction(() => {
          this.users = users;
          this.total = total;
        });
      })
      .catch(() => {
        // TODO alert error
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
