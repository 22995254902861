import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from '../../../base/hooks/useRootStore';

const RequireAuth = observer(({ children }: { children: JSX.Element }) => {
  const { authStore } = useRootStore();
  const { isAuthorized } = authStore;
  const location = useLocation();
  const navigate = useNavigate();
  const [isCheckedAuth, setCheckedAuth] = useState(false);

  useEffect(() => {
    // children is visible for some milliseconds
    setTimeout(() => {
      setCheckedAuth(true);
    }, 500);
    if (!isAuthorized) {
      return navigate('/login', { state: { from: location }, replace: true });
    }
  }, [isAuthorized, setCheckedAuth]);

  if (!isCheckedAuth) {
    return <></>;
  }

  return children;
});

export default RequireAuth;
