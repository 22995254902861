import { makeAutoObservable, runInAction } from 'mobx';
import LessonTestService from '../../lesson-test/LessonTestService';
import LessonsService from '../LessonsService';
import Lesson from '../models/Lesson';

export default class LessonsStore {
  isLoading = true;
  lessons: Lesson[] = [];

  private lessonsService: LessonsService;
  private lessonTestService: LessonTestService;

  constructor() {
    this.lessonsService = new LessonsService();
    this.lessonTestService = new LessonTestService();
    makeAutoObservable(this);
  }

  init = ({ lectureId }: { lectureId: string }) => {
    this.getLessons(lectureId);
  };

  getLessons = (lectureId: string) => {
    this.isLoading = true;
    return this.lessonsService
      .getLessons(lectureId)
      .then((lessons) => {
        runInAction(() => {
          this.lessons = lessons;
        });
      })
      .catch((_) => {
        //@TODO alert error
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  delete = (id: string | null) => {
    if (!id) {
      return;
    }
    this.isLoading = true;
    this.lessonsService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.lessons = this.lessons.filter((l) => l.id !== id);
        });
      })
      .catch((error) => {
        // console.log({ error });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  createTest = ({ lessonId, onSuccess }: { lessonId: string; onSuccess: (lessonTestId: string) => void }) => {
    this.lessonTestService
      .create(lessonId)
      .then((lessonTest) => {
        if (!lessonTest.id) {
          throw new Error('no lessonTestId');
        }
        onSuccess(lessonTest.id);
      })
      .catch(() => {
        //@TODO alert error
      });
  };
}
