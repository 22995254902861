import AbstractApiRepository from '../../../base/api/AbstractApiRepository';
import { CreateUserTariffDto } from '../dto/CreateUserTariffDto';

export class UserTariffApiRepository extends AbstractApiRepository {
  create = (data: CreateUserTariffDto) => {
    return this.apiClient.post<any>({
      url: `/user-tariffs`,
      data,
    });
  };

  delete = (id: string) => {
    return this.apiClient.delete<any>({
      url: `/user-tariffs/${id}`,
    });
  };
}
