export const LIMIT = 20;

export const TableColumns = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Фамилия',
    accessor: 'lastName',
  },
  {
    Header: 'Имя',
    accessor: 'name',
  },
  {
    Header: 'Отчество',
    accessor: 'patronymic',
  },
  {
    Header: 'Телефон',
    accessor: 'phone',
  },
  {
    Header: 'Подтвержден',
    accessor: 'verified',
    Cell: (props: any) => {
      return props?.cell?.value ? 'Да' : 'Нет';
    },
  },
];
